import React, { Component } from "react";

class Step extends Component {
  render() {
    const { steps, currentStep } = this.props;
    return (
      <ul className="steps is-large">
        {steps.map((step, index) => {
          return (
            <li
              className={`step-item is-info ${
                currentStep >= index + 1 ? "is-completed" : null
              } ${currentStep === index ? "is-active" : null}`}
              key={step.label}
            >
              <div className="step-marker">
                {step.icon ? (
                  <span className="icon">
                    <i className={`fa ${step.icon}`} />
                  </span>
                ) : (
                  step.label
                )}
              </div>
            </li>
          );
        })}
      </ul>
    );
  }
}

export default Step;
