import React, { Component } from 'react';

class Loading extends Component {
	render() {
		const { isLoading } = this.props;
		return (
			<div className={isLoading ? 'pageloader is-active' : 'pageloader'}>
				<span className="title">LOADING</span>
			</div>
		);
	}
}

export default Loading;
