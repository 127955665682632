import React, { Component } from "react";
import Parse from "parse";
import { Link } from "react-router-dom";
import _ from "lodash";
import Loading from "./Loading";
import CSVFile from "./CSVFile";
import { yearStart } from "../utils";
class AdminDashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      users: [],
      filteredUsers: [],
      isLoading: true,
      completedFilter: true,
      isModalOpen: false,
      selectedUser: null,
      year: yearStart
    };
  }
  getUsers = async (year, completedFilter) => {
    await Parse.Cloud.run("getUsers")
      .then(users => users)
      .then(data => {
        //   debugger;
        // console.log('----- Completed Filter -----');
        // console.log(completedFilter);
        // console.log('----- Year Filter -----');
        // console.log(year);
        //   console.log("----- Users -----");
        //   console.log(data);
        const usersData = _.sortBy(
          data,
          user => user.attributes.firstName
        ).filter(user => !user.attributes.isAdmin);

        const filteredUsers = _.sortBy(data, user => {
          return user.attributes.firstName;
          // }).filter(user => user.attributes.completed === this.state.completedFilter);
        }).filter(user => {
          if (completedFilter) {
            if (
              user.attributes.recommendationsCount > 1 &&
              !user.attributes.isAdmin &&
              user.attributes.completed === true
            ) {
              return user;
            }
          } else {
            if (
              user.attributes.recommendationsCount < 2 &&
              !user.attributes.isAdmin &&
              user.attributes.completed === true
            ) {
              return user;
            } else if (
              user.attributes.recommendationsCount < 2 &&
              !user.attributes.isAdmin &&
              user.attributes.completed === false
            ) {
              return user;
            } else if (
              user.attributes.recommendationsCount > 1 &&
              !user.attributes.isAdmin &&
              user.attributes.completed === false
            ) {
              return user;
            }
          }
          return false;
        });
        // console.log('----- Filtered users -----');
        // console.log(filteredUsers);
        this.setState({
          users: usersData,
          filteredUsers,
          completedFilter,
          isLoading: false
        });
        // console.log('----- Users -----');
        // console.log(users);
        return usersData;
      })
      .catch(error => {
        return alert(error);
      });
  };
  confirmDeleteUser = () => {
    const { id } = this.state.selectedUser;
    this.deleteUser(id);
    this.toggleModal(null);
  };
  cancelDeleteUser = () => {
    this.setState({ selectedUser: null });
    this.toggleModal(null);
  };
  deleteUser = async id => {
    const { year, completedFilter } = this.state;
    await Parse.Cloud.run("deleteUser", { id })
      .then(result => {
        this.setState({ selectedUser: null });
        this.getUsers(year, completedFilter);
        return result;
      })
      .catch(error => {
        // console.log(error);
        return alert(error);
      });
  };
  toggleModal = selectedUser => {
    this.setState(prevState => {
      return {
        isModalOpen: !prevState.isModalOpen,
        selectedUser
      };
    });
  };
  completionStatus = event => {
    console.log({ event });
    let { filteredUsers, year } = this.state;
    let completedFilter = JSON.parse(event.currentTarget.value);
    console.log("----- Filter -----");
    console.log(completedFilter);
    // console.log('----- Users -----');
    // console.log(users);
    // console.log('----- Filtered Users -----');
    // console.log(users.filter(user => user.attributes.completed === completedFilter));
    // filteredUsers = users.filter(user => user.attributes.completed === completedFilter);
    // if (completedFilter) {
    // 	filteredUsers = users.filter(user => user.attributes.recommendationsCount >= 2 && user.attributes.completed === completedFilter);
    // } else {
    // 	filteredUsers = users.filter(user => user.attributes.recommendationsCount <= 2 && user.attributes.completed === completedFilter);
    // }

    // console.log(filteredUsers);

    this.setState({
      completedFilter,
      filteredUsers
    });
    this.getUsers(year, completedFilter);
  };

  yearStatus = event => {
    const { completedFilter } = this.state;
    let year = event.currentTarget.value;
    // console.log(year);
    this.setState({ year });
    this.getUsers(year, completedFilter);
  };

  componentDidMount() {
    const { year, completedFilter } = this.state;
    this.getUsers(year, completedFilter);
  }
  render() {
    const {
      isLoading,
      completedFilter,
      filteredUsers,
      isModalOpen,
      year
    } = this.state;
    const {
      completionStatus,
      toggleModal,
      cancelDeleteUser,
      confirmDeleteUser,
      yearStatus
    } = this;
    return (
      <React.Fragment>
        {isLoading ? (
          <Loading isLoading={isLoading} />
        ) : (
          <React.Fragment>
            <div className={isModalOpen ? "modal is-active" : "modal"}>
              <div
                className="modal-background"
                onClick={() => toggleModal(null)}
              />
              <div className="modal-card">
                <header className="modal-card-head">
                  <p className="modal-card-title">Delete Applicant</p>
                  <button
                    className="delete"
                    aria-label="close"
                    onClick={() => toggleModal(null)}
                  />
                </header>
                <section className="modal-card-body">
                  <p className="subtitle is-5">
                    Are you sure you want to delete applicant{" "}
                    <b>
                      {this.state.selectedUser &&
                        this.state.selectedUser.attributes.username}
                    </b>
                    ?
                  </p>
                  <p>
                    This action is irreversible and all of{" "}
                    <b>
                      {this.state.selectedUser &&
                        this.state.selectedUser.attributes.username}
                    </b>{" "}
                    data will be deleted.
                  </p>
                </section>
                <footer className="modal-card-foot">
                  <button className="button" onClick={cancelDeleteUser}>
                    Cancel
                  </button>
                  <button
                    className="button is-primary is-pulled-right"
                    onClick={confirmDeleteUser}
                  >
                    Delete Applicant
                  </button>
                </footer>
              </div>
            </div>
            <section>
              <section className="hero is-primary">
                <div className="hero-body">
                  <div className="container">
                    <h1 className="title">Admin Dashboard</h1>
                    <h2 className="subtitle">
                      <b className="subtitle">View Applicant Submissions</b>
                    </h2>
                  </div>
                </div>
              </section>
            </section>
            <section className="section">
              <div className="container">
                <div className="columns is-multiline is-vcentered">
                  <div className="column is-narrow">
                    <div className="field">
                      <label htmlFor="completedFilter">Completion Status</label>
                      <div className="control">
                        <div className="select is-fullwidth-mobile">
                          <select
                            name="completedFilter"
                            id="completedFilter"
                            value={completedFilter}
                            onChange={completionStatus}
                          >
                            <option value="true">Completed</option>
                            <option value="false">Not Completed</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <div className="column is-narrow">
											<div className="field">
												<label htmlFor="completedFilter">Year</label>
												<div className="control">
													<div className="select is-fullwidth-mobile">
														<select
															name="yearFilter"
															id="yearFilter"
															value={year}
															onChange={yearStatus}
														>
															<option value="2020-01-01">2020</option>
															<option value="2019-01-01">2019</option>
														</select>
													</div>
												</div>
											</div>
										</div> */}
                  <div className="column has-text-right">
                    <CSVFile data={filteredUsers} />
                  </div>
                  <div className="column is-full">
                    <div className="box">
                      <table className="table is-fullwidth">
                        <thead>
                          <tr>
                            <th className="is-hidden-mobile"></th>
                            <th>First Name</th>
                            <th>LastName</th>
                            <th className="has-text-centered is-hidden-mobile">
                              Age
                            </th>
                            <th className="has-text-centered is-hidden-mobile">
                              State
                            </th>
                            <th className="has-text-centered is-hidden-mobile">
                              Approval Status
                            </th>
                            <th className="has-text-centered">Actions</th>
                          </tr>
                        </thead>
                        <tbody>
                          {filteredUsers &&
                            _.sortBy(filteredUsers, user => {
                              return user.attributes.firstName;
                            }).map((user, index) => (
                              <tr key={user.id}>
                                <th className="is-hidden-mobile">
                                  {index + 1}
                                </th>
                                <th>{user.attributes.firstName}</th>
                                <th>{user.attributes.lastName}</th>
                                <th className="has-text-centered is-hidden-mobile">
                                  {user.attributes.age}
                                </th>
                                <th className="has-text-centered is-hidden-mobile">
                                  {user.attributes.state}
                                </th>
                                <th className="has-text-centered is-hidden-mobile">
                                  {user.attributes.isApproved === undefined
                                    ? "Not Started"
                                    : null}
                                  {user.attributes.isApproved === true ? (
                                    <span
                                      className="icon has-text-info tooltip"
                                      data-tooltip="Approved"
                                    >
                                      <i className="fas fa-check-circle has-text-success" />
                                    </span>
                                  ) : null}
                                  {user.attributes.isApproved === false ? (
                                    <span
                                      className="icon has-text-info tooltip"
                                      data-tooltip="Denied"
                                    >
                                      <i className="fas fa-times-circle has-text-danger" />
                                    </span>
                                  ) : null}
                                </th>
                                <th className="has-text-centered">
                                  <Link
                                    to={`/applicant/${user.id}`}
                                    className="tooltip"
                                    data-tooltip="View Application"
                                    target="_blank"
                                  >
                                    <span className="icon has-text-info">
                                      <i className="fas fa-address-card" />
                                    </span>
                                  </Link>
                                  <span
                                    className="icon has-text-danger tooltip is-hidden-mobile"
                                    onClick={() => this.toggleModal(user)}
                                    data-tooltip="Delete Applicant"
                                  >
                                    <i className="fas fa-minus-circle" />
                                  </span>
                                </th>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }
}

export default AdminDashboard;
