import React, { Component } from 'react';
import { Formik, Field } from 'formik';

class ContactDetails extends Component {
	constructor(props) {
		super(props);
		this.state = {
			type: null
		};
	}
	render() {
		const { formData, submit } = this.props;
		const { type } = this.state;
		return (
			<Formik
				initialValues={formData}
				onSubmit={(values, { setSubmitting }) => {
					setTimeout(() => {
						submit(type, values);
						setSubmitting(false);
					}, 400);
				}}
			>
				{({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
					<form onSubmit={handleSubmit} className="columns is-multiline">
						<div className="column is-full">
							<b className="subtitle is-4">Contact Details</b>
						</div>

						<div className="column is-half">
							<div className="field">
								<div className="control">
									<label htmlFor="mobile">Mobile*</label>
									<Field className="input is-medium" type="tel" name="mobile" required />
								</div>
							</div>
						</div>
						<div className="column is-half">
							<div className="field">
								<div className="control">
									<label htmlFor="phone">Phone</label>
									<Field className="input is-medium" type="tel" name="phone" />
								</div>
							</div>
						</div>

						<div className="column is-half">
							<div className="field">
								<div className="control">
									<label htmlFor="address">Address*</label>
									<Field className="input is-medium" type="text" name="address" required />
								</div>
							</div>
						</div>
						<div className="column is-half">
							<div className="field">
								<div className="control">
									<label htmlFor="apt">Apt. #</label>
									<Field className="input is-medium" type="text" name="apt" />
								</div>
							</div>
						</div>
						<div className="column is-half">
							<div className="field">
								<div className="control">
									<label htmlFor="city">City*</label>
									<Field className="input is-medium" type="text" name="city" required />
								</div>
							</div>
						</div>
						<div className="column is-half">
							<div className="field">
								<div className="control">
									<label htmlFor="state">State*</label>
									<div className="select is-medium full-width">
										<Field component="select" name="state" required>
											<option value="">Select</option>
											<option value="AL">Alabama</option>
											<option value="AK">Alaska</option>
											<option value="AZ">Arizona</option>
											<option value="AR">Arkansas</option>
											<option value="CA">California</option>
											<option value="CO">Colorado</option>
											<option value="CT">Connecticut</option>
											<option value="DE">Delaware</option>
											<option value="DC">District Of Columbia</option>
											<option value="FL">Florida</option>
											<option value="GA">Georgia</option>
											<option value="HI">Hawaii</option>
											<option value="ID">Idaho</option>
											<option value="IL">Illinois</option>
											<option value="IN">Indiana</option>
											<option value="IA">Iowa</option>
											<option value="KS">Kansas</option>
											<option value="KY">Kentucky</option>
											<option value="LA">Louisiana</option>
											<option value="ME">Maine</option>
											<option value="MD">Maryland</option>
											<option value="MA">Massachusetts</option>
											<option value="MI">Michigan</option>
											<option value="MN">Minnesota</option>
											<option value="MS">Mississippi</option>
											<option value="MO">Missouri</option>
											<option value="MT">Montana</option>
											<option value="NE">Nebraska</option>
											<option value="NV">Nevada</option>
											<option value="NH">New Hampshire</option>
											<option value="NJ">New Jersey</option>
											<option value="NM">New Mexico</option>
											<option value="NY">New York</option>
											<option value="NC">North Carolina</option>
											<option value="ND">North Dakota</option>
											<option value="OH">Ohio</option>
											<option value="OK">Oklahoma</option>
											<option value="OR">Oregon</option>
											<option value="PA">Pennsylvania</option>
											<option value="RI">Rhode Island</option>
											<option value="SC">South Carolina</option>
											<option value="SD">South Dakota</option>
											<option value="TN">Tennessee</option>
											<option value="TX">Texas</option>
											<option value="UT">Utah</option>
											<option value="VT">Vermont</option>
											<option value="VA">Virginia</option>
											<option value="WA">Washington</option>
											<option value="WV">West Virginia</option>
											<option value="WI">Wisconsin</option>
											<option value="WY">Wyoming</option>
										</Field>
									</div>
								</div>
							</div>
						</div>
						<div className="column is-half">
							<div className="field">
								<div className="control">
									<label htmlFor="city">Zip*</label>
									<Field className="input is-medium" type="number" name="zipcode" required />
								</div>
							</div>
						</div>

						<div className="column is-full">
							<button
								type="submit"
								className="button is-large is-primary is-pulled-right"
								name="next"
								onClick={event => this.setState({ type: event.currentTarget.name })}
							>
								Next
							</button>
							<button
								type="submit"
								className="button is-large is-pulled-right"
								name="back"
								onClick={event => this.setState({ type: event.currentTarget.name })}
							>
								Back
							</button>
						</div>
					</form>
				)}
			</Formik>
		);
	}
}

export default ContactDetails;
