import React, { Component } from "react";

class Hobbies extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hobbies: [],
      type: null,
      hobby: "",
    };
  }
  componentDidMount() {
    const { hobbies } = this.props.formData;
    if (hobbies && hobbies.length > 0) {
      this.setState({ hobbies });
    }
  }
  addHobby = e => {
    e.preventDefault();
    let { hobby } = this.state;
    this.setState(prevState => {
      let hobbies = prevState.hobbies;
      const entry = { name: hobby };
      hobbies.push(entry);
      hobby = "";
      return {
        hobbies,
        hobby,
      };
    });
  };
  removeHobby = (e, item) => {
    e.preventDefault();
    let hobbies = this.state.hobbies;
    hobbies.splice(item, 1);
    this.setState({ hobbies });
  };

  handleChange = event => {
    // console.log(event.currentTarget.name);
    const value = { [event.target.name]: event.currentTarget.value };
    // console.log(value);
    this.setState(value);
  };
  submit = () => {
    const { type } = this.state;
    const hobbies = { hobbies: this.state.hobbies };
    // console.log(hobbies);
    this.props.submit(type, hobbies);
  };
  render() {
    const { addHobby, removeHobby, handleChange, submit } = this;
    const { hobbies, hobby } = this.state;
    return (
      <div className="columns is-multiline">
        <div className="column is-full">
          <b className="subtitle is-4">Other Interests</b>
          <p>
            <b>
              Please list any other interests you may have i.e. hobbies, social
              causes, etc.
            </b>
          </p>
        </div>

        <div className="column is-10">
          <div className="field">
            <div className="control">
              <label htmlFor="activity">Interest</label>
              <input
                className="input is-medium"
                type="text"
                name="hobby"
                value={hobby}
                onChange={handleChange}
              />
            </div>
          </div>
        </div>

        <div className="column is-2 flex flex-end flex-column">
          <button
            className="button is-medium is-info"
            onClick={addHobby}
            disabled={!hobby}
          >
            Add
          </button>
        </div>

        {hobbies && hobbies.length > 0 ? (
          <div className="column is-full">
            <div className="box">
              <table className="table is-fullwidth">
                <thead>
                  <tr>
                    <th>Interest</th>
                    <th className="has-text-right">Delete</th>
                  </tr>
                </thead>
                <tbody>
                  {hobbies.map((hobby, index) => (
                    <tr key={index}>
                      <td>{hobby.name}</td>
                      <td className="has-text-right">
                        <button
                          className="button is-medium is-danger"
                          onClick={event => removeHobby(event, index)}
                        >
                          <i className="fas fa-times" />
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        ) : null}

        <div className="column is-full">
          <button
            type="submit"
            className="button is-large is-primary is-pulled-right"
            name="next"
            onClick={event =>
              this.setState({ type: event.currentTarget.name }, () => submit())
            }
          >
            Next
          </button>
          <button
            type="submit"
            className="button is-large is-pulled-right"
            name="back"
            onClick={event =>
              this.setState({ type: event.currentTarget.name }, () => submit())
            }
          >
            Back
          </button>
        </div>
      </div>
    );
  }
}

export default Hobbies;
