import React, { Component } from 'react';

class MessageBox extends Component {
	render() {
		const { title, children, isVisible, type, dismiss } = this.props;
		return (
			<React.Fragment>
				{isVisible && (
					<article className={`box is-paddingless message ${type}`}>
						<div className="message-header">
							<p>{title}</p>
							{dismiss && <button className="delete" aria-label="delete" onClick={dismiss} />}
						</div>
						<div className="message-body">
							<div className="content">{children}</div>
						</div>
					</article>
				)}
			</React.Fragment>
		);
	}
}

export default MessageBox;
