import React, { Component } from 'react';
import Parse from 'parse';
import Loading from '../components/Loading';
import Recommendations from '../components/Recommendations';
import ReadDetails from '../components/ReadDetails';
import MessageBox from '../components/MessageBox';
import {fixFileLinks} from '../utils';

class Applicant extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isLoading: true,
			userData: [],
			currentUser: Parse.User.current()
		};
	}
	componentDidMount() {
		this.getUserDetails(this.props.match.params.id);
	}
	getUserDetails = async id => {
		Parse.Cloud.run('getUser', { id })
			.then(async user => {
				// console.warn('----- Found User -----');
				// console.warn(user);
				await Parse.Cloud.run('getUserRecommendations', { id })
					.then(recommendations => {
						// console.warn('----- Retrieved Recommendations -----');
						// console.warn(recommendations);
						this.setState({
							user,
							userData: user.attributes,
							isLoading: false,
							recommendations,
						});
						return recommendations;
					})
					.catch(error => {
						// console.warn('----- Error Retrieving Recommendations -----');
						// console.warn(error);
						return alert(error);
					});
				return user;
			})
			.catch(error => {
				// console.warn('----- Error Retrieving User -----');
				// console.warn(error);
				return alert(error);
			});
	};
	render() {
		const { isLoading, recommendations, userData, currentUser } = this.state;
		const { firstName, lastName, recommendationsCount, completed, essay, report_card } = this.state.userData;
		const report_card_link = fixFileLinks(report_card);
		const essay_link = fixFileLinks(essay);

		return (
			<React.Fragment>
				{isLoading ? (
					<Loading isLoading={isLoading} />
				) : (
					<React.Fragment>
						<section className="hero is-primary">
							<div className="hero-body">
								<div className="container">
									<h1 className="title">
										{firstName} {lastName}
									</h1>
									<h2 className="subtitle">
										<b className="subtitle">Jeter's Leaders Application</b>
									</h2>
								</div>
							</div>
						</section>
						<section className="section">
							<div className="container">
							
							{completed}
								<MessageBox type="is-danger" title="Application Incomplete" isVisible={!completed || recommendationsCount < 2}>
									{recommendationsCount < 2 ? (<p>{firstName} {lastName} has <b>{recommendationsCount}</b> of the <b>2</b> minimum required, application is incomplete.</p>) : null}
									{!essay ? (<p>Supporting document <b>Essay</b> not found</p>): null}
									{!report_card ? (<p>Supporting document <b>Report Card</b> not found</p>): null}
								</MessageBox>

								<MessageBox type="is-success" title="Application Complete" isVisible={recommendationsCount >= 2 && completed}>
									<p>{firstName} {lastName} has <b>{recommendationsCount}</b> of the <b>2</b> minimum required.</p>
									<p>{firstName} {lastName} has provided supporting document: <a href={essay_link} target="_blank" rel="noopener noreferrer"><b>Essay</b></a></p>
									<p>{firstName} {lastName} has provided supporting document: <a href={report_card_link} target="_blank" rel="noopener noreferrer"><b>Report Card</b></a></p>
								</MessageBox>

								<ReadDetails userData={userData} user={currentUser}/>

								{recommendations.length > 0 && (
									<div className="box">
										<div className="columns is-multiline">
											<div className="column is-full">
												<div className="subtitle is-4">Recommendations</div>
											</div>
											{recommendations.map(recommendation => (
												<Recommendations data={recommendation} key={recommendation.id} />
											))}
										</div>
									</div>
								)}
							</div>
						</section>
					</React.Fragment>
				)}
			</React.Fragment>
		);
	}
}

export default Applicant;
