import React, { Component } from 'react';
import Parse from 'parse';
import { Route, Redirect, Switch } from 'react-router-dom';

import Nav from './components/Nav';
import Login from './components/Login';
import Home from './pages/Home';
import Applicant from './pages/Applicant';
import Users from './pages/Users/Users';
import Recommendation from './pages/Recommendation';
import Footer from './components/Footer';
import UserDashboard from './components/UserDashboard';
import AdminDashboard from './components/AdminDashboard';

class App extends Component {
	constructor(props) {
		super(props);
		Parse.initialize(process.env.REACT_APP_ID);
		Parse.serverURL = process.env.REACT_APP_API_URL;
		this.users = new Parse.Query('User');
		this.state = {
			currentUser: Parse.User.current(),
			isModalOpen: false,
			isRegistrating: false,
			isResettingPassword: false,
			isLoggingIn: false,
			userExists: false,
		};
	}
	toggleLoginModal = e => {
		if (e.currentTarget.name === 'register') {
			this.setState({
				isRegistrating: true,
				isLoggingIn: false,
				isModalOpen: true
			});
		} else if (e.currentTarget.name === 'login') {
			this.setState({
				isRegistrating: false,
				isLoggingIn: true,
				isModalOpen: true
			});
		} else if (e.currentTarget.title === 'reset') {
			this.setState({
				isResettingPassword: true,
				isRegistrating: false,
				isLoggingIn: false,
				isModalOpen: true
			});
		} else if (e.currentTarget.title === 'back-reset') {
			this.setState({
				isResettingPassword: false,
				isRegistrating: false,
				isLoggingIn: true,
				isModalOpen: true
			});
		} else {
			this.setState({
				isResettingPassword: false,
				isRegistrating: false,
				isLoggingIn: false,
				isModalOpen: false
			});
		}
	};
	register = async (data, history) => {
		const that = this;
		const account = data.email.toLowerCase();
		data.email = account;
		data.username = account;
		Parse.Cloud.run('getUser', { data }).then(user => {
			// console.log('----- User Result -----');
			// console.log(user);
			if (user) {
				this.setState({ userExists: true });
				alert('User already exists, did you forget your password? If so use the Forgot Password link to reset it.');
			} else {
				Parse.Cloud.run('registerUser', { data })
					.then(user => {
						// console.log('----- Registered New User -----');
						// console.log(user);
						that.setState({ currentUser: user, isModalOpen: false });
						return user;
					})
					.then(user => {
						// console.log('----- User -----');
						// console.log(user);
						// console.log('----- Redirecting To Dashboard -----');
						history.push(`/my-application/${user.id}`);
					})
					.catch(error => {
						// console.log('----- Error Registering User -----');
						// console.log(error);
						alert(error);
						return error;
					});
			}
		})
	};
	logIn = async (values, history) => {
		const { email, password } = values;
		return await Parse.User.logIn(email, password)
			.then(() => {
				const currentUser = Parse.User.current();
				this.setState({ currentUser, isModalOpen: false });
				if (currentUser.attributes.isAdmin) {
					history.push(`/my-dashboard/${currentUser.id}`);
				} else {
					history.push(`/my-application/${currentUser.id}`);
				}
			})
			.catch(error => {
				return alert(error);
			});
	};

	logOut = async () => {
		return await Parse.User.logOut().then(() => {
			this.setState({ currentUser: Parse.User.current() }); // this will now be null
		});
	};
	resetPassword = email => {
		Parse.User.requestPasswordReset(email)
			.then(() => {
				// Password reset request was sent successfully
				this.setState({
					isModalOpen: false,
					isRegistrating: false,
					isResettingPassword: false,
					isLoggingIn: false,
				});
				alert('Please check your email for instructions on how to reset your password.');
			})
			.catch(error => {
				// Show the error message somewhere
				alert('Error: ' + error.code + ' ' + error.message);
			});
	};

	render() {
		const { currentUser, isModalOpen, isRegistrating, isResettingPassword, isLoggingIn, userExists } = this.state;
		const { logOut, toggleLoginModal, logIn, register, resetPassword } = this;
		return (
			<React.Fragment>
				{!currentUser ? (
					<Login
						toggleModalAction={toggleLoginModal}
						isModalOpen={isModalOpen}
						loginAction={logIn}
						isRegistrating={isRegistrating}
						registerAction={register}
						forgotPassword={resetPassword}
						isResettingPassword={isResettingPassword}
						isLoggingIn={isLoggingIn}
						userExists={userExists}
					/>
				) : null}

				<header>
					<Nav logout={logOut} user={currentUser} openModal={toggleLoginModal} />
				</header>
				<Switch>
					<Route exact path="/" render={() => <Home openModal={toggleLoginModal} user={currentUser} />} />
					<Route exact path="/recommendation/:id" component={Recommendation} />

					{currentUser && currentUser.attributes && !currentUser.attributes.isAdmin ? (
						<React.Fragment>
							<Route exact path="/my-application/:id" component={UserDashboard} />
						</React.Fragment>
					) : null}
					{/* if logged in and Admin*/}
					{currentUser && currentUser.attributes && currentUser.attributes.isAdmin ? (
						<React.Fragment>
							<Route exact path="/my-dashboard/:id" component={AdminDashboard} />
							<Route exact path="/users" component={Users} />
							<Route exact path="/applicant/:id" component={Applicant} />
						</React.Fragment>
					) : null}
					<Redirect to="/" />
				</Switch>
				<Footer />
			</React.Fragment>
		);
	}
}

export default App;
