import React, { Component } from 'react';
import Parse from 'parse';
import Loading from '../../components/Loading';
class Users extends Component {
	constructor(props) {
		super(props);
		this.state = {
			users: [],
			isModalOpen: false,
			selectedUser: null,
			isLoading: true
		};
		this.users = new Parse.Query('User');
		// this.userChanges = this.users.subscribe();
	}
	getUsers = async () => {
		await Parse.Cloud.run('getUsers')
			.then(users => {
				users = users.filter(user => user.attributes.isAdmin);
				this.setState({ users, isLoading: false });
				return users;
			})
			.catch(error => {
				return alert(error);
			});
	};
	confirmDeleteUser = () => {
		const { id } = this.state.selectedUser;
		this.deleteUser(id);
		this.toggleModal(null);
	};
	cancelDeleteUser = () => {
		this.setState({ selectedUser: null });
		this.toggleModal(null);
	};
	deleteUser = async id => {
		await Parse.Cloud.run('deleteUser', { id })
			.then(result => {
				this.setState({ selectedUser: null });
				this.getUsers();
				return result;
			})
			.catch(error => {
				// console.log(error);
				return alert(error);
			});
	};
	toggleModal = selectedUser => {
		this.setState(prevState => {
			return {
				isModalOpen: !prevState.isModalOpen,
				selectedUser
			};
		});
	};
	componentDidMount() {
		this.getUsers();
	}

	render() {
		const { users, isModalOpen, isLoading } = this.state;
		const { toggleModal, confirmDeleteUser, cancelDeleteUser } = this;
		return (
			<React.Fragment>
				{isLoading ? (
					<Loading isLoading={isLoading} />
				) : (
					<React.Fragment>
						<div className={isModalOpen ? 'modal is-active' : 'modal'}>
							<div className="modal-background" onClick={() => toggleModal(null)} />
							<div className="modal-card">
								<header className="modal-card-head">
									<p className="modal-card-title">Delete User</p>
									<button className="delete" aria-label="close" onClick={() => toggleModal(null)} />
								</header>
								<section className="modal-card-body">
									<p className="subtitle is-5">
										Are you sure you want to delete user{' '}
										<b>{this.state.selectedUser && this.state.selectedUser.attributes.username}</b>?
									</p>
									<p>
										This action is irreversible and all of{' '}
										<b>{this.state.selectedUser && this.state.selectedUser.attributes.username}</b>{' '}
										data will be deleted.
									</p>
								</section>
								<footer className="modal-card-foot">
									<button className="button" onClick={cancelDeleteUser}>
										Cancel
									</button>
									<button className="button is-primary is-pulled-right" onClick={confirmDeleteUser}>
										Delete User
									</button>
								</footer>
							</div>
						</div>

						<section className="hero is-primary">
							<div className="hero-body">
								<div className="container">
									<h1 className="title">User Management</h1>
									<h2 className="subtitle">
										<b className="subtitle">Modify User Permissions</b>
									</h2>
								</div>
							</div>
						</section>
						<section className="section">
							<div className="container">
								<div className="box">
									<table className="table is-fullwidth">
										<thead>
											<tr>
												<th>Name</th>
												<th>Email</th>
												<th>Actions</th>
											</tr>
										</thead>
										<tbody>
											{users &&
												users.map(user => (
													<tr key={user.id}>
														<th>
															{user.attributes.firstName} {user.attributes.lastName}
														</th>
														<th>{user.attributes.email}</th>
														<th>
															<button onClick={() => this.toggleModal(user)}>
																Delete user
															</button>
														</th>
													</tr>
												))}
										</tbody>
									</table>
								</div>
							</div>
						</section>
					</React.Fragment>
				)}
			</React.Fragment>
		);
	}
}

export default Users;
