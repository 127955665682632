import React, { Component } from 'react';
import { Formik, Field } from 'formik';

class EducationDetails extends Component {
	constructor(props) {
		super(props);
		this.state = {
			type: null
		};
	}
	render() {
		const { formData, submit } = this.props;
		const { type } = this.state;
		return (
			<Formik
				initialValues={formData}
				onSubmit={(values, { setSubmitting }) => {
					setTimeout(() => {
						submit(type, values);
						setSubmitting(false);
					}, 400);
				}}
			>
				{({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
					<form onSubmit={handleSubmit} className="columns is-multiline">
						<div className="column is-full">
							<b className="subtitle is-4">Education Details</b>
						</div>
						<div className="column is-half">
							<div className="field">
								<div className="control">
									<label htmlFor="school">Current School*</label>
									<Field className="input is-medium" type="text" name="current_school" required />
								</div>
							</div>
						</div>
						<div className="column is-half">
							<div className="field">
								<div className="control">
									<label htmlFor="grade">Current Grade*</label>
									<Field className="input is-medium" type="text" name="grade" required />
								</div>
							</div>
						</div>
						<div className="column is-half">
							<div className="field">
								<div className="control">
									<label htmlFor="principal">Principal*</label>
									<Field className="input is-medium" type="text" name="principal" required />
								</div>
							</div>
						</div>
						<div className="column is-half">
							<div className="field">
								<div className="control">
									<label htmlFor="counselor">Guidance Counselor*</label>
									<Field className="input is-medium" type="text" name="counselor" required />
								</div>
							</div>
						</div>

						<div className="column">
							<div className="columns is-multiline is-vcentered is-full">
								<div className="column">
									<div className="field">
										<div className="control">
											<label htmlFor="high_school">High School*</label>
											<Field
												className="input is-medium"
												type="text"
												name="high_school"
												required
											/>
										</div>
									</div>
								</div>
								<div className="column">
									<div className="field">
										<div className="control">
											<input
												className="is-checkradio is-medium"
												type="radio"
												id="high_school_confirmed"
												name="high_school_confirmed"
												value="Confirmed"
												onChange={handleChange}
												required
												checked={values.high_school_confirmed === 'Confirmed'}
											/>
											<label htmlFor="high_school_confirmed" className="is-size-6">Confirmed</label>
											<input
												className="is-checkradio is-medium"
												type="radio"
												id="high_school_pending"
												name="high_school_confirmed"
												value="Pending"
												onChange={handleChange}
												required
												checked={values.high_school_confirmed === 'Pending'}
											/>
											<label htmlFor="high_school_pending" className="is-size-6">Pending</label>
										</div>
									</div>
								</div>
							</div>
						</div>

						<div className="column is-full">
							<button
								type="submit"
								className="button is-large is-primary is-pulled-right"
								name="next"
								onClick={event => this.setState({ type: event.currentTarget.name })}
							>
								Next
							</button>
							<button
								type="submit"
								className="button is-large is-pulled-right"
								name="back"
								onClick={event => this.setState({ type: event.currentTarget.name })}
							>
								Back
							</button>
						</div>
					</form>
				)}
			</Formik>
		);
	}
}

export default EducationDetails;
