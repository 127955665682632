import React, { Component } from 'react';
import Parse from 'parse';
import GeneralInformation from '../components/GeneralInformation';
import LeadershipQualities from '../components/LeadershipQualities';
import ShortAnswers from '../components/ShortAnswers';
import Step from '../components/Step';
import Unauthorized from '../components/Unauthorized';
import MessageBox from '../components/MessageBox';
import { dueDateDisplay } from '../utils';
class Recommendation extends Component {
	constructor(props) {
		super(props);
		this.state = {
			user: null,
			step: 0,
			formData: {},
			unauthorized: false
		};
	}
	componentDidMount() {
		const { id } = this.props.match.params;
		const currentUser = Parse.User.current();
		// console.log("----- User ID -----");
		// console.log(id);

		console.log(currentUser)
		if (currentUser?.id === id) {
			this.setState({
				unauthorized: true
			});
		} else {
			this.getUser(id);
		}
	}
	getUser = id => {
		Parse.Cloud.run('getUser', { id }).then(user => {
			this.setState({
				formData: {
					userId: user.id,
					userName: `${user.attributes.firstName} ${user.attributes.lastName}`
				}
			});
			return user;
		});
	};
	setFormData = values => {
		this.setState(prevState => {
			const formData = Object.assign(prevState.formData, values);
			let step = (prevState.step += 1);
			// console.log("----- Form Data -----");
			// console.log(formData);
			return {
				formData,
				step
			};
		});
		if (this.state.step === 3) {
			this.saveformData(this.state.formData);
		}
	};
	stepBack = () => {
		this.setState(prevState => {
			return {
				step: (prevState.step -= 1)
			};
		});
	};
	saveformData = (data, id) => {
		Parse.Cloud.run('saveRecommendation', { data, id })
			.then(result => {
				// console.log("----- Saved Recommendation Data -----");
				// console.log(result);
				return result;
			})
			.catch(error => {
				// console.log("----- Error Saving Recommendation Data -----");
				// console.log(error);
				return error;
			});
	};
	steps = [
		{
			label: 1,
			icon: null
		},
		{
			label: 2,
			icon: null
		},
		{
			label: 3,
			icon: 'fa-flag'
		}
	];
	render() {
		const { step, formData, unauthorized } = this.state;
		const { setFormData, steps, stepBack } = this;
		return (
			<React.Fragment>
				{!unauthorized ? (
					<React.Fragment>
						<section className="hero is-primary">
							<div className="hero-body">
								<div className="container">
									<h1 className="title">
										{formData && formData.userName ? `${formData.userName}` : null}
									</h1>
									<h2 className="subtitle">Jeter's Leader Recommendation Form</h2>
								</div>
							</div>
						</section>
						{step < 3 ? (
							<section className="section">
								<div className="container">
									<Step steps={steps} currentStep={step} />
								</div>
							</section>
						) : null}
						<section className="section">
							<div className="container">
								{step < 3 ? (
									<MessageBox type="is-info" title="Important" isVisible>
										<p>Recommendations are due by {dueDateDisplay}.</p>
										<p>
											<b>
												By signing this recommendation form, you are certifying that the
												information provided is accurate to the best of your knowledge.
												Please note this form is confidential and will not be shared with the student.
											</b>
										</p>
									</MessageBox>
								) : null}
								{step === 0 ? (
									<React.Fragment>
										<GeneralInformation submit={setFormData} />
									</React.Fragment>
								) : null}
								{step === 1 ? (
									<React.Fragment>
										<LeadershipQualities submit={setFormData} back={stepBack} />
									</React.Fragment>
								) : null}
								{step === 2 ? (
									<React.Fragment>
										<ShortAnswers submit={setFormData} back={stepBack} userInfo={formData} />
									</React.Fragment>
								) : null}
								{step === 3 ? (
									<React.Fragment>
										<div className="content">
											<MessageBox type="is-success" title="Recommendation Completed" isVisible>
												<p className="is-size-4">Thank you for taking the time and recommending {formData.userName} as a potential candidate to the Jeter's Leaders Program.</p>
											</MessageBox>
										</div>
									</React.Fragment>
								) : null}
							</div>
						</section>
					</React.Fragment>
				) : (
						<Unauthorized />
					)}
			</React.Fragment>
		);
	}
}

export default Recommendation;
