import React, { Component } from 'react';
import {isRegistrationOver, fixFileLinks } from '../utils';
class componentName extends Component {
	render() {
		const {
			firstName,
			lastName,
			age,
			birthdate,
			address,
			mobile,
			phone,
			apt,
			zipcode,
			state,
			city,
			parentEmail,
			parentName,
			parentPhone,
			school,
			principal,
			counselor,
			grade,
			activities,
			colleges,
			careers,
			hobbies,
			high_school_confirmed,
			high_school,
			email,
			report_card,
			essay,
			recommendationsCount
		} = this.props.userData;
		const { edit } = this.props;
		const { isAdmin } = this.props.user.attributes;
		const report_card_link = fixFileLinks(report_card);
		const essay_link = fixFileLinks(essay);
		return (
			<div className="box">
				<div className="columns is-multiline">
					<div className="column is-full">
						<div className="columns is-vcentered is-mobile">
							<div className="column">
								<div className="title is-4">My Application</div>
							</div>
							 {!isRegistrationOver() && !isAdmin? (
								<div className="column">
									<button className="button is-pulled-right is-info" onClick={edit}>
										Edit
									</button>
								</div>
							) : null}
						</div>
					</div>
					<div className="column is-full">
						<div className="is-divider is-marginless" />
					</div>
					<div className="column">
						<div className="content">
							<div className="subtitle is-4">Personal Details</div>
							<p>
								<b>Name:</b> {firstName} {lastName}
								<br />
								<b>Birthdate:</b> {birthdate}
								<br />
								<b>Age:</b> {age}
							</p>
							<p>
								<b>Email:</b> <a href={`mailto:${email}`}>{email}</a>
							</p>
						</div>
					</div>
					<div className="column">
						<div className="content">
							<div className="subtitle is-4">Contact Details</div>
							{mobile ? (
								<p>
									<b>Mobile:</b> {mobile}
								</p>
							) : null}
							{phone ? (
								<p>
									<b>Phone:</b> {phone}
								</p>
							) : null}
							<p>
								<b>Address:</b> {address}
								<br />
								<b>Apt. #:</b> {apt}
								<br />
								<b>City:</b> {city}
								<br />
								<b>State:</b> {state}
								<br />
								<b>Zip:</b> {zipcode}
							</p>
						</div>
					</div>
					<div className="column">
						<div className="content">
							<div className="subtitle is-4">Education Details</div>
							<p>
								<b>School:</b> {school}
							</p>
							<p>
								<b>High School:</b> {high_school} - {high_school_confirmed}
							</p>
							<p>
								<b>Current Grade:</b> {grade}
							</p>
							<p>
								<b>Principal:</b> {principal}
							</p>
							<p>
								<b>Guidance Counselor:</b> {counselor}
							</p>
						</div>
					</div>
				</div>

				<div className="columns">
					<div className="column">
						<div className="content">
							<div className="subtitle is-4">Activities</div>
							{activities && activities.length > 0 ? (
								activities.map((activity, index) => (
									<p key={index}>
										<b>Activity:</b> {activity.name}
										<br />
										<b>Start Date:</b> {activity.startDate}
										<br />
										<b>End Date:</b> {activity.endDate}
									</p>
								))
							) : (
								<p>No Activities Submitted</p>
							)}
						</div>
					</div>
					<div className="column">
						<div className="content">
							<div className="subtitle is-4">College of Interest</div>
							{colleges && colleges.length > 0 ? (
								colleges.map((college, index) => (
									<p key={index}>
										<b>College:</b> {college.name}
									</p>
								))
							) : (
								<p>No Colleges Submitted</p>
							)}
						</div>
					</div>
					<div className="column">
						<div className="content">
							<div className="subtitle is-4">Careers of Interest</div>
							{careers && careers.length > 0 ? (
								careers.map((career, index) => (
									<p key={index}>
										<b>Career:</b> {career.name}
									</p>
								))
							) : (
								<p>No Careers Submitted</p>
							)}
						</div>
					</div>

					<div className="column">
						<div className="content">
							<div className="subtitle is-4">Other Interests</div>
							{hobbies && hobbies.length > 0 ? (
								hobbies.map((hobby, index) => (
									<p key={index}>
										<b>Hobby:</b> {hobby.name}
									</p>
								))
							) : (
								<p>No Hobbies Submitted</p>
							)}
						</div>
					</div>
				</div>

				<div className="column" />

				{essay || report_card ? (
					<React.Fragment>
						<div className="is-divider" />
						<div className="columns is-marginless">
							<div className="column">
								<div className="subtitle is-4">Support Documents</div>
							</div>
							<div className="is-divider-vertical" />
							{report_card && (
								<div className="column">
									<p>
										<a href={report_card_link} target="_blank" rel="noopener noreferrer">
											Report Card
										</a>
									</p>
								</div>
							)}
							{essay && (
								<div className="column">
									<p>
										<a href={essay_link} target="_blank" rel="noopener noreferrer">
											Essay
										</a>
									</p>
								</div>
							)}
						</div>
					</React.Fragment>
				) : null}

				<div className="is-divider" />
				<div className="columns is-marginless">
					<div className="column">
						<div className="subtitle is-4">Guardian Details</div>
					</div>
					<div className="is-divider-vertical" />
					<div className="column">
						<p>
							<b>Name:</b> {parentName}
						</p>
					</div>
					<div className="column">
						<p>
							<b>Email:</b> <a href={`mailto:${parentEmail}`}>{parentEmail}</a>
						</p>
					</div>
					<div className="column">
						<p>
							<b>Daytime Phone Number:</b> {parentPhone}
						</p>
					</div>
				</div>

				<div className="is-divider" />

				<div className="columns is-marginless">
					<div className="column">
						<div className="subtitle is-4">Recommendations Received: <span className={recommendationsCount >= 2 ? 'has-text-success' : 'has-text-danger'}>{recommendationsCount}</span></div>
					</div>
				</div>
			</div>
		);
	}
}

export default componentName;
