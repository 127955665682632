import React, { Component } from "react";

class Recommendations extends Component {
  render() {
    const {
      academic_ability,
      academic_ability_details,
      activities,
      address,
      benefits,
      city,
      contribute,
      email,
      fax,
      firstName,
      imagination_creativity,
      imagination_creativity_details,
      lastName,
      lead_group,
      lead_group_details,
      leadership,
      motivation_initiative,
      motivation_initiative_details,
      printed_name,
      relationship,
      school_org,
      state,
      strengths,
      succeed_leadership,
      succeed_leadership_details,
      telephone,
      work_with_others,
      work_with_others_details,
      zip,
    } = this.props.data.attributes;
    return (
      <React.Fragment>
        <div className="referrer column is-full">
          <div className="columns is-multiline">
            <div className="column">
              <div className="content">
                <div className="subtitle is-4">Referrer Details</div>
                <p>
                  <b>Name:</b> {firstName} {lastName}
                  <br />
                  <b>Relationship:</b> {relationship}
                  <br />
                  <b>Email:</b> <a href={`mailto:${email}`}>{email}</a>
                </p>
                <p>
                  <b>School/Organization:</b> {school_org}
                  <br />
                  <b>Address:</b> {address}
                  <br />
                  <b>City:</b> {city}
                  <br />
                  <b>State:</b> {state}
                  <br />
                  <b>Zip:</b> {zip}
                  <br />
                </p>
                <p>
                  <b>Phone:</b> {telephone}
                  <br />
                  {fax && (
                    <React.Fragment>
                      <b>Fax:</b> {fax}
                    </React.Fragment>
                  )}
                </p>
              </div>
            </div>
            <div className="column">
              <div className="content">
                <div className="subtitle is-4">Leadership Qualities</div>
                <p>
                  <b>General Academic Ability:</b> {academic_ability}
                  {academic_ability_details && (
                    <React.Fragment>
                      <br />
                      <b>Details:</b> {academic_ability_details}
                    </React.Fragment>
                  )}
                </p>
                <p>
                  <b>Imagination and Creativity:</b> {imagination_creativity}
                  {imagination_creativity_details && (
                    <React.Fragment>
                      <br />
                      <b>Details:</b> {imagination_creativity_details}
                    </React.Fragment>
                  )}
                </p>
                <p>
                  <b>Motivation and Initiative:</b> {motivation_initiative}
                  {motivation_initiative_details && (
                    <React.Fragment>
                      <br />
                      <b>Details:</b> {motivation_initiative_details}
                    </React.Fragment>
                  )}
                </p>
                <p>
                  <b>Ability to Work with Others:</b> {work_with_others}
                  {work_with_others_details && (
                    <React.Fragment>
                      <br />
                      <b>Details:</b> {work_with_others_details}
                    </React.Fragment>
                  )}
                </p>
                <p>
                  <b>Capability to Lead a Group:</b> {lead_group}
                  {lead_group_details && (
                    <React.Fragment>
                      <br />
                      <b>Details:</b> {lead_group_details}
                    </React.Fragment>
                  )}
                </p>
                <p>
                  <b>Potential to Succeed in a Leadership Program:</b>{" "}
                  {succeed_leadership}
                  {succeed_leadership_details && (
                    <React.Fragment>
                      <br />
                      <b>Details:</b> {succeed_leadership_details}
                    </React.Fragment>
                  )}
                </p>
              </div>
            </div>
            <div className="column is-full mt-6 mb-6">
              <div className="content">
                <div className="subtitle is-4">Short Answers</div>
                <p>
                  <b>What are the applicant’s outstanding strengths?</b>
                  <br />
                  {strengths}
                </p>
                <p>
                  <b>
                    List known activities the applicant has been involved in
                    that will help him/her contribute to the Program.
                  </b>
                  <br />
                  {activities}
                </p>
                <p>
                  <b>
                    What leadership qualities does the applicant have that would
                    make him/her an asset to the Program?
                  </b>
                  <br />
                  {leadership}
                </p>
                <p>
                  <b>
                    In what ways will the applicant benefit from the Jeter’s
                    Leaders Program?
                  </b>
                  <br />
                  {benefits}
                </p>
                <p>
                  <b>
                    In what ways will the applicant be able to contribute to the
                    Jeter’s Leaders Program?
                  </b>
                  <br />
                  {contribute}
                </p>
              </div>
            </div>
          </div>
          <div className="columns is-multiline">
            {printed_name && (
              <div className="column is-half">
                <div className="subtitle is-4">Printed Name</div>
                <p>{printed_name}</p>
              </div>
            )}
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default Recommendations;
