import React, { Component } from 'react';
import Parse from 'parse';
import { Link, withRouter } from 'react-router-dom';
import _ from 'lodash';
import Loading from './Loading';
import Step from './Step';
import PersonalDetails from './PersonalDetails';
import ContactDetails from './ContactDetails';
import GuardianDetails from './GuardianDetails';
import EducationDetails from './EducationDetails';
import Activities from './Activities';
import CollegeInterest from './CollegeInterest';
import CareerInterest from './CareerInterest';
import Hobbies from './Hobbies';
import InfoBox from './InfoBox';
import ReadDetails from './ReadDetails';
import MessageBox from './MessageBox';
import FileUpload from './FileUpload';
import Contract from './Contract';
import { isRegistrationOver, dueDateDisplay } from '../utils';
class UserDashboard extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isLoading: true,
			user: Parse.User.current(),
			selectedUser: null,
			formData: {
				step: 0
			},
			infoBox: {
				isVisible: false,
				message: null,
				type: null
			},
			file: null,
			fileName: null
		};
		this.query = new Parse.Query('User');
	}
	componentDidMount() {
		const { id } = this.props.match.params;
		this.getUserDetails(id);
	}

	query = new Parse.Query('User');

	getUserDetails = async id => {
		await Parse.Cloud.run('getUser', { id })
			.then(user => {
				// console.warn("----- Found User -----");
				this.setState({ user, formData: user.attributes, isLoading: false });
				return user;
			})
			.catch(error => {
				// console.warn("----- Error Retrieving User -----");
				// console.warn(error);
				return alert(error);
			});
	};
	updateUserInfo = async userData => {
		const { user, formData } = this.state;
		let data = Object.assign({}, userData ? userData : formData);
		if ((formData.report_card && data.essay) || (data.report_card && formData.essay)) {
			data.completed = true;
		}
		this.saveUserDetails(data, user.id);
		// console.log("----- User Data -----");
		// console.log(data);
	};

	saveUserDetails = (data, id) => {
		Parse.Cloud.run('updateUser', { data, id })
			.then(result => {
				// console.log("----- Updated User -----");
				// console.log(result);
				this.setState({
					infoBox: {
						message: 'Your progress has been saved.',
						isVisible: true,
						type: 'is-success'
					},
					autoDismiss: true
				});
				// console.warn(result);
				this.getUserDetails(id);
				return result;
			})
			.catch(error => {
				// console.error(`Error updating user: ${error.code} ${error.message}`);
				this.setState({
					infoBox: {
						message: 'Error Saving.',
						isVisible: true,
						type: 'is-danger'
					},
					autoDismiss: true
				});
				return error;
			});
	};

	advanceStep = (button, data) => {
		let { step } = this.state.formData;
		let formData = Object.assign({}, data);
		if (step === 0) {
			formData.firstName = _.startCase(formData.firstName);
			formData.lastName = _.startCase(formData.lastName);
		}
		if (button === 'next') {
			formData.step = step += 1;
		} else {
			formData.step = step -= 1;
		}
		this.updateUserInfo(formData);
	};

	dismiss = () => {
		this.setState(prevState => {
			const infoBox = prevState.infoBox;
			infoBox.isVisible = false;
			return {
				infoBox
			};
		});
	};

	autoDismiss = () => {
		setTimeout(() => {
			this.setState(prevState => {
				const infoBox = prevState.infoBox;
				infoBox.isVisible = false;
				return {
					infoBox
				};
			});
		}, 3000);
	};

	saveFile = (file, field) => {
		// console.log("----- File -----");
		// console.log(file);
		// Parse.Cloud.run("saveFile", { file })
		//   .then(file => console.log(file))
		//   .catch(error => {
		// 		console.log(error);
		// 		alert(error.message)}
		// 		);
		const savedFile = new Parse.File(_.kebabCase(file.name), file);
		savedFile
			.save()
			.then(fileURL => {
				let data = {};
				data[field] = fileURL.url();
				this.updateUserInfo(data);
			})
			.catch(error => {
				// console.log("----- Save File Error -----");
				// console.log(error);
				return alert(error);
			});
	};

	editApplication = () => {
		let { formData } = this.state;
		let data = Object.assign({}, formData);
		data.step = 0;
		data.completed = false;
		this.updateUserInfo(data);
	};

	steps = [
		{
			label: 1,
			icon: null
		},
		{
			label: 2,
			icon: null
		},
		{
			label: 3,
			icon: null
		},
		{
			label: 4,
			icon: null
		},
		{
			label: 5,
			icon: null
		},
		{
			label: 6,
			icon: null
		},
		{
			label: 7,
			icon: null
		},
		{
			label: null,
			icon: 'fa-flag'
		}
	];

	render() {
		const { isLoading, formData, infoBox, user } = this.state;
		const { dismiss, steps, autoDismiss, advanceStep, saveFile, editApplication } = this;

		if (infoBox.isVisible) {
			autoDismiss();
		}

		return (
			<React.Fragment>
				<InfoBox isVisible={infoBox.isVisible} dismiss={dismiss} type={infoBox.type} isFixed>
					<p>{infoBox.message}</p>
				</InfoBox>
				{isLoading ? (
					<Loading isLoading={isLoading} />
				) : (
						<React.Fragment>
							<section className="hero is-primary">
								<div className="hero-body">
									<div className="container">
										<h1 className="title">
											{formData?.firstName && formData?.lastName
												? `${formData.firstName} ${formData.lastName}`
												: null}
										</h1>
										<h2 className="subtitle">
											<b className="subtitle">
												Approval Status:{' '}
												{formData?.isApproved ? 'Approved' : 'Under Review'}
											</b>
										</h2>
									</div>
								</div>
							</section>
							{formData.step < 8 ? (
								<section className="section">
									<div className="container">
										<Step steps={steps} currentStep={formData.step} />
									</div>
								</section>
							) : null}
							<section className="section">
								<div className="container">
									{formData.step === 0 ? (
										<PersonalDetails submit={advanceStep} formData={formData} />
									) : null}
									{formData.step === 1 ? (
										<ContactDetails submit={advanceStep} formData={formData} back={advanceStep} />
									) : null}
									{formData.step === 2 ? (
										<GuardianDetails submit={advanceStep} formData={formData} back={advanceStep} />
									) : null}
									{formData.step === 3 ? (
										<EducationDetails submit={advanceStep} formData={formData} back={advanceStep} />
									) : null}
									{formData.step === 4 ? (
										<Activities submit={advanceStep} formData={formData} back={advanceStep} />
									) : null}
									{formData.step === 5 ? (
										<CollegeInterest submit={advanceStep} formData={formData} back={advanceStep} />
									) : null}
									{formData.step === 6 ? (
										<CareerInterest submit={advanceStep} formData={formData} back={advanceStep} />
									) : null}
									{formData.step === 7 ? (
										<Hobbies submit={advanceStep} formData={formData} back={advanceStep} />
									) : null}
									{formData.step === 8 ? (
										<Contract submit={advanceStep} formData={formData} back={advanceStep} />
									) : null}
									{formData.step === 9 ? (
										<React.Fragment>

											{/* Registration period over message box */}
											<MessageBox type="is-danger" title="Registration Period Over" isVisible={isRegistrationOver()}>
												<p>Application can not be updated, registration period is over.</p>
											</MessageBox>
											{/* Registration period over message box */}

											{/* Start Application Incomplete Message Box */}
											<MessageBox
												type={formData.recommendationsCount >= 2 ? "is-success" : "is-danger"}
												title={formData.recommendationsCount >= 2 ? "Recommendations Complete" : "Recommendations Incomplete"}
												isVisible={!isRegistrationOver()}
											>
												<p>You have <b>{formData.recommendationsCount}</b> completed recommendations of the <b>2</b> minimum required, {formData.recommendationsCount >= 2 && formData.completed ? 'your recommendations are complete.' : 'your application will not be reviewed until you receive 2 or more recommendations.'}</p>
												{formData.recommendationsCount >= 2 && formData.completed ? null : <React.Fragment>
													<p>Copy and Paste this link and share it with your teachers, coaches, program directors, principals or community leaders in order to have your application considered for review: <b>
														<Link to={`/recommendation/${user.id}`}>{`${
															window.location.origin
															}/recommendation/${user.id}`}</Link>{' '}
													</b></p>

													<p><b>(We highly recommend following up with your educators about this before {dueDateDisplay}).</b></p>
												</React.Fragment>}
											</MessageBox>
											{/* End Application Incomplete Message Box */}

											<MessageBox
												type={formData.recommendationsCount >= 2 && formData.completed && formData.report_card && formData.essay ? 'is-success' : 'is-warning'}
												title={formData.recommendationsCount >= 2 && formData.completed && formData.report_card && formData.essay ? 'Application Complete' : 'Next Steps'}
												isVisible={!isRegistrationOver()}
											>
												<p className="is-size-5">
													{formData.recommendationsCount >= 2 && formData.completed && formData.report_card && formData.essay ?
														(<span>Congratulations, you have completed your application successfully. <br /> After our staff reviews your application we will contact you with our decision for eligibility. Good Luck!</span>) :
														(<span>There are still a few more things for you to do before the due date on <b>{dueDateDisplay}</b>.</span>)
													}
												</p>
												<ul>
													{formData.recommendationsCount >= 2 ? null : (<li>
														<b>Follow up with your educators to see if they have completed your recommendation form.</b>
													</li>)}
													<li>
														{!formData.report_card && (
															<p>
																Submit a copy of your most recent <b>report card</b>
															</p>
														)}
														<div className="columns is-vcentered">
															<div className="column is-narrow">
																<FileUpload label="Report Card" saveFile={saveFile} />
															</div>
															<div className="column">
																{formData.report_card && (
																	<p>
																		You already uploaded your report card but if you need to
																		update it just upload a new file.
														</p>
																)}
															</div>
														</div>
													</li>

													<li>
														{!formData.essay && (
															<p>
																<b>
																	Upload a three hundred word essay that addresses the
																	following topic:
															</b>
																<br />
																Derek Jeter knows that it is important for young people to
																develop themselves with the help of positive role models. As
																such, he maintains a sound commitment to
																community service, and promotes the importance of good
																education. Describe ways in which you, as a Jeter’s Leader,
																will serve as a positive role model within your school and
																community, and describe how you will make a difference in
																the lives of younger children and your peers. Be sure to
																include what you consider to be your personal strengths and
																weaknesses.
														</p>
														)}
														<div className="columns is-vcentered">
															<div className="column is-narrow">
																<FileUpload label="Essay" saveFile={saveFile} />
															</div>
															<div className="column">
																{formData.essay && (
																	<p>
																		You already uploaded your essay but if you need to update it
																		just upload a new file.
														</p>
																)}
															</div>
														</div>
													</li>
												</ul>
											</MessageBox>

											<ReadDetails userData={formData} edit={editApplication} user={user} />

										</React.Fragment>
									) : null}
								</div>
							</section>
						</React.Fragment>
					)}
			</React.Fragment>
		);
	}
}

export default withRouter(UserDashboard);
