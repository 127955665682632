import React, { Component } from "react";

class Unauthorized extends Component {
  render() {
    return (
      <React.Fragment>
        <section className="hero is-primary">
          <div className="hero-body">
            <div className="container">
              <h1 className="title">Unauthorized Access</h1>
              <h2 className="subtitle">
                You don't have permission to access this page.
              </h2>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

export default Unauthorized;
