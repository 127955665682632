import { compareAsc, format, startOfYear, subDays } from "date-fns";

const dueDate = new Date(process.env.REACT_APP_DUE_DATE);

export const yearStart = format(startOfYear(new Date()), "yyyy-MM-dd");

export const dueDateDisplay = format(subDays(dueDate, 1), "iiii, LLLL d, yyyy");

export function isRegistrationOver() {
  const today = new Date();
  if (compareAsc(today, dueDate) === 1) {
    return true;
  } else {
    return false;
  }
}

export function fixFileLinks(file) {
  if (!file) {
    return;
  } else if (window.origin !== "https://jetersleadersapplication.com") {
    return file;
  } else if (file.startsWith("undefined/")) {
    file = file.replace(
      "undefined/",
      "https://jetersleadersapplication.com/parse/"
    );
  } else {
    file = file.replace(
      "http://localhost:1337/",
      "https://jetersleadersapplication.com/"
    );
  }

  return file;
}
