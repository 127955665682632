import React, { Component } from 'react';
import _ from 'lodash';
class Activities extends Component {
	constructor(props) {
		super(props);
		this.state = {
			activities: [],
			type: null,
			endDateType: 'current'
		};
	}
	componentDidMount() {
		const { activities } = this.props.formData;
		if (activities && activities.length > 0) {
			this.setState({ activities });
		}
	}
	addActivity = e => {
		e.preventDefault();
		let { activity, startDate, endDate, endDateType } = this.state;
		this.setState(prevState => {
			let activities = prevState.activities;
			if (endDateType === 'current') {
				endDate = _.startCase(endDateType);
			}
			const entry = { name: activity, startDate, endDate };
			activities.push(entry);
			activity = '';
			startDate = '';
			endDate = '';
			return {
				activities,
				activity,
				startDate,
				endDate,
				endDateType: 'current'
			};
		});
	};
	remove = (e, item) => {
		e.preventDefault();
		let activities = this.state.activities;
		activities.splice(item, 1);
		this.setState({ activities });
	};

	handleChange = event => {
		// console.log(event.currentTarget.name);
		const value = { [event.target.name]: event.currentTarget.value };
		// console.log(value);
		this.setState(value);
	};
	submit = () => {
		const { type } = this.state;
		const activities = { activities: this.state.activities };
		// console.log(activities);
		this.props.submit(type, activities);
	};
	setDateType = e => {
		this.setState({ [e.currentTarget.name]: e.currentTarget.value });
	};
	resetDateType = () => {
		this.setState({ endDateType: 'current' });
	};
	render() {
		const { addActivity, handleChange, submit, remove, setDateType, resetDateType } = this;
		const { activities, activity, startDate, endDate, endDateType } = this.state;
		return (
			<div className="columns is-multiline">
				<div className="column is-full">
					<b className="subtitle is-4">Extracurricular Activities</b>
					<p>
						<b>
							Please list extracurricular activities including academic, sports, recreation and community
							service
						</b>
					</p>
				</div>
				<div className="column is-half">
					<div className="field">
						<div className="control">
							<label htmlFor="activity">Activity</label>
							<input
								className="input is-medium"
								type="text"
								name="activity"
								value={activity}
								onChange={handleChange}
							/>
						</div>
					</div>
				</div>
				<div className="column is-half">
					<div className="columns">
						<div className="column">
							<div className="field">
								<div className="control">
									<label htmlFor="startDate">Start Date</label>
									<input
										className="input is-medium"
										type="date"
										name="startDate"
										value={startDate}
										onChange={handleChange}
									/>
								</div>
							</div>
						</div>
						<div className="column">
							<div className="field">
								<div className="control">
									<label htmlFor="endDateType">End Date</label>
									{endDateType === 'current' && (
										<div className="select is-medium full-width">
											<select name="endDateType" id="endDateType" onChange={setDateType}>
												<option value="current">Current</option>
												<option value="date">Date</option>
											</select>
										</div>
									)}
									{endDateType === 'date' && (
										<React.Fragment>
											<div className="field has-addons">
												<div className="control">
													<input
														className="input is-medium"
														type="date"
														name="endDate"
														value={endDate}
														onChange={handleChange}
													/>
												</div>
												<div className="control">
													<button
														className="button is-medium is-danger"
														onClick={resetDateType}
													>
														<i className="fas fa-times" />
													</button>
												</div>
											</div>
										</React.Fragment>
									)}
								</div>
							</div>
						</div>
						<div className="column flex flex-end flex-column">
							<button
								className="button is-medium is-info"
								onClick={addActivity}
								disabled={
									!activity || !startDate || !endDateType || (endDateType === 'date' && !endDate)
								}
							>
								Add
							</button>
						</div>
					</div>
				</div>

				{activities && activities.length > 0 ? (
					<div className="column is-full">
						<div className="box">
							<table className="table is-fullwidth">
								<thead>
									<tr>
										<th>Activity</th>
										<th className="has-text-centered">Start Date</th>
										<th className="has-text-centered">End Date</th>
										<th className="has-text-right">Delete</th>
									</tr>
								</thead>
								<tbody>
									{activities.map((activity, index) => (
										<tr key={activity.name}>
											<td>{activity.name}</td>
											<td className="has-text-centered">{activity.startDate}</td>
											<td className="has-text-centered">{activity.endDate}</td>
											<td className="has-text-right">
												<button
													className="button is-medium is-danger"
													onClick={event => remove(event, index)}
												>
													<i className="fas fa-times" />
												</button>
											</td>
										</tr>
									))}
								</tbody>
							</table>
						</div>
					</div>
				) : null}

				<div className="column is-full">
					<button
						type="submit"
						className="button is-large is-primary is-pulled-right"
						name="next"
						onClick={event => this.setState({ type: event.currentTarget.name }, () => submit())}
					>
						Next
					</button>
					<button
						type="submit"
						className="button is-large is-pulled-right"
						name="back"
						onClick={event => this.setState({ type: event.currentTarget.name }, () => submit())}
					>
						Back
					</button>
				</div>
			</div>
		);
	}
}

export default Activities;
