import React, { Component } from "react";
import banner from "../assets/images/homepage-banner2.jpg";
import sideImage from "../assets/images/homepage-image1.jpg";
import NY from "../assets/pdfs/2025/NYJL-Recruitment-Dates-2025.pdf";
import KZ from "../assets/pdfs/2025/KZJL-Recruitment-Dates-2025.pdf";
import { dueDateDisplay, isRegistrationOver } from "../utils";
import turn2Video from "../assets/videos/jl-2021-recruitment-video.mp4";
import turn2Poster from "../assets/videos/jl-2021-recruitment-video.jpg";

class Home extends Component {
  render() {
    const { openModal, user } = this.props;
    return (
      <React.Fragment>
        <section>
          <div className="banner">
            <img src={banner} alt="Apply Today!" />
          </div>
          <div className="hero-box is-info has-text-centered">
            <div className="hero-title">JETER’S LEADERS PROGRAM</div>
            {/* <div className="hero-subtitle">Apply Today!</div> */}
          </div>
        </section>
        <section className="section">
          <div className="container">
            <div className="content">
              <div className="columns is-multiline">
                <div className="column is-full">
                  {/* <div className="message is-warning">
										<div className="message-header">
											<p>IMPORTANT UPDATE</p>
										</div>
										<div className="message-body">
											<p>The health and safety of our students, team, partners and friends remain the Turn 2 Foundation’s top priority as the Coronavirus (COVID-19) impacts communities across the U.S. and around the world. Due to the recent school closures, we will be extending the application process for the Jeter’s Leaders program.</p>
											<p>We are happy to share the application site will be open until <b>{dueDateDisplay}</b>. Be sure to check the documents listed below that outline mandatory interview dates and upcoming events as that has changed. Please note, this is all tentative and will be reevaluated as we continue to closely monitor the Coronavirus and its impact on our communities. Thank you in advance for your understanding and participation</p>
										</div>
									</div> */}

                  <video controls poster={turn2Poster}>
                    <source src={turn2Video} type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>
                </div>
                <div className="column is-half">
                  <div className="section-title subtitle is-4">
                    About The Jeter's Leaders Program
                  </div>
                  <p>
                    Jeter’s Leaders is a leadership development program created
                    to empower, recognize, and enhance the skills of high school
                    students who
                  </p>
                  <ul>
                    <li>
                      Promote healthy lifestyles, free of alcohol and substance
                      abuse
                    </li>
                    <li>Achieve academically</li>
                    <li>
                      Are committed to improving their community through social
                      change activities
                    </li>
                    <li>
                      Serve as role models to younger students and deliver
                      positive messages to their peers.
                    </li>
                  </ul>

                  <div className="section-title subtitle is-4">
                    Info Sessions
                  </div>

                  <p>
                    If you like an info session please e-mail{" "}
                    <a href="mailto:kparkman@turn2foundation.org ">Kendra Parkman </a>{" "}
                    for Kalamazoo or{" "}
                    <a href="mailto:kortega@turn2foundation.org">
                    Karina Ortega
                    </a>{" "}
                    for New York.
                  </p>

                  <p>
                    To learn more about the Jeter’s Leaders program and the Turn
                    2 Foundation click{" "}
                    <a
                      href="http://www.turn2foundation.org"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <b>here</b>
                    </a>
                  </p>

                  <div className="section-title subtitle is-4">
                    Eligibility Criteria
                  </div>
                  <p>
                    <b>In order to be eligible, the applicant must</b>
                  </p>
                  <ul>
                    <li>Be a high school freshman in the Fall</li>
                    <li>Be drug and alcohol free</li>
                    <li>Have a minimum GPA of 3.0 (B)</li>
                    <li>
                      Be involved in community service, extracurricular
                      activities, and/or sports
                    </li>
                    <li>
                      Be a resident of the New York Metropolitan or Western
                      Michigan Area
                    </li>
                    <li>
                      Have a desire to be dedicated to the Program and promote
                      social change
                    </li>
                    <li>
                      Must be able to attend these dates in your service area:
                      <ul>
                        <li>
                          <a
                            href={NY}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            New York
                          </a>
                        </li>
                        <li>
                          <a
                            href={KZ}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            Kalamazoo
                          </a>
                        </li>
                      </ul>
                    </li>
                  </ul>
                  <p>
                    <b>
                      <i>Please note:</i>
                    </b>
                  </p>
                  <ul>
                    <li>
                      Siblings cannot be a part of the program concurrently
                    </li>
                    <li>
                      Family members of employees and the Board of Directors are
                      not eligible to apply for the Jeter’s Leaders Program
                    </li>
                  </ul>
                </div>

                <div className="column is-half">
                  <img src={sideImage} alt="Jeter's Leaders" />
                </div>
              </div>
              <div className="column is-full">
                <div className="section-title subtitle is-4">
                  Application Requirements
                </div>
                <ul>
                  <li>
                    Complete the online application and agreement by{" "}
                    <b>{dueDateDisplay}</b>
                  </li>
                  <li>
                    Submit two completed recommendation forms from teachers,
                    coaches, program directors, principals or community leaders
                    (Recommendation Link provided at the end of application)
                  </li>
                  <li>Upload a copy of your most recent report card</li>
                  <li>
                    Upload a three hundred word essay (details provided at the
                    end of your application)
                  </li>
                </ul>
                {!user && !isRegistrationOver() ? (
                  <div className="flex justify-center">
                    <button
                      name="register"
                      onClick={openModal}
                      className="button is-primary is-large"
                    >
                      Register
                    </button>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

export default Home;
