import React, { Component } from 'react';
import { Formik, Field } from 'formik';

class PersonalDetails extends Component {
	constructor(props) {
		super(props);
		this.state = {
			type: null
		};
	}
	render() {
		const { formData, submit } = this.props;
		const { type } = this.state;
		return (
			<Formik
				initialValues={formData}
				onSubmit={(values, { setSubmitting }) => {
					setTimeout(() => {
						submit(type, values);
						setSubmitting(false);
					}, 400);
				}}
			>
				{({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting, props }) => (
					<form onSubmit={handleSubmit} className="columns is-multiline">
						<div className="column is-full">
							<div className="subtitle is-4">Personal Details</div>
						</div>
						<div className="column is-half">
							<div className="field">
								<div className="control">
									<label htmlFor="firstName">First Name*</label>
									<Field validate required className="input is-medium" type="text" name="firstName" />
								</div>
							</div>
						</div>
						<div className="column is-half">
							<div className="field">
								<div className="control">
									<label htmlFor="lastName">Last Name*</label>
									<Field required className="input is-medium" type="text" name="lastName" />
								</div>
							</div>
						</div>
						<div className="column is-half">
							<div className="field">
								<div className="control">
									<label htmlFor="birthdate">Birthdate*</label>
									<Field required className="input is-medium" type="date" name="birthdate" />
								</div>
							</div>
						</div>
						<div className="column is-half">
							<div className="field">
								<div className="control">
									<label htmlFor="age">Age*</label>
									<Field required className="input is-medium" type="number" name="age" />
								</div>
							</div>
						</div>

						<div className="column is-full">
							<button
								type="submit"
								className="button is-large is-primary is-pulled-right"
								name="next"
								onClick={event => this.setState({ type: event.currentTarget.name })}
							>
								Next
							</button>
						</div>
					</form>
				)}
			</Formik>
		);
	}
}

export default PersonalDetails;
