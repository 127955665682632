import React, { Component } from 'react';
import { NavLink, Link } from 'react-router-dom';
import logo from '../assets/images/turn2-logo.svg';
import { isRegistrationOver } from '../utils';

class Nav extends Component {
	constructor(props) {
		super(props);
		this.state = { isNavbarOpen: false };
	}
	activateNavbar = () => {
		this.setState(prevState => {
			return {
				isNavbarOpen: !prevState.isNavbarOpen
			};
		});
	};
	render() {
		const { user, logout, openModal } = this.props;
		const { activateNavbar } = this;
		const { isNavbarOpen } = this.state;
		return (
			<nav className="navbar" role="navigation" aria-label="main navigation">
				<div className="navbar-brand">
					<Link className="navbar-item" to="/">
						<img src={logo} className="logo" alt="Turn 2 Foundation Inc." />
					</Link>
					<button
						className="navbar-burger burger"
						aria-label="menu"
						aria-expanded="false"
						data-target="navbarBasicExample"
						onClick={activateNavbar}
					>
						<span aria-hidden="true" />
						<span aria-hidden="true" />
						<span aria-hidden="true" />
					</button>
				</div>

				<div id="navbar" className={isNavbarOpen ? 'navbar-menu is-active' : 'navbar-menu'}>
					<div className="navbar-start">
						{user && user.attributes ? (
							<React.Fragment>
								{!user.attributes.isAdmin ? (
									<NavLink to={`/my-application/${user.id}`} className="navbar-item">
										My Application
									</NavLink>
								) : (
									<NavLink to={`/my-dashboard/${user.id}`} className="navbar-item">
										My Dashboard
									</NavLink>
								)}
							</React.Fragment>
						) : null}
						{/* if logged in and Admin*/}
						{user?.attributes?.isAdmin ? (
							<React.Fragment>
								<NavLink to="/users" className="navbar-item">
									Users
								</NavLink>
							</React.Fragment>
						) : null}
					</div>

					<div className="navbar-end">
						<div className="navbar-item">
							<div className="buttons">
								{/* anonymous */}
								{!user && !isRegistrationOver()? (<button name="register" onClick={openModal} className="button is-primary">
											Register
										</button>) : null}
								{!user ? (
									<React.Fragment>

										<button name="login" onClick={openModal} className="button is-light">
											Login
										</button>
									</React.Fragment>
								) : null}
								{/* if logged in and Admin*/}
								{user ? (
									<NavLink to="/" onClick={logout} className="button is-primary">
										Log Out
									</NavLink>
								) : null}
							</div>
						</div>
					</div>
				</div>
			</nav>
		);
	}
}

export default Nav;
