import React, { Component } from "react";

class CareerInterest extends Component {
  constructor(props) {
    super(props);
    this.state = {
      careers: [],
      type: null,
      career: "",
    };
  }
  componentDidMount() {
    const { careers } = this.props.formData;
    if (careers && careers.length > 0) {
      this.setState({ careers });
    }
  }
  addCareer = e => {
    e.preventDefault();
    let { career } = this.state;
    this.setState(prevState => {
      let careers = prevState.careers;
      const entry = { name: career };
      careers.push(entry);
      career = "";
      return {
        careers,
        career,
      };
    });
  };
  removeCareer = (e, item) => {
    e.preventDefault();
    let careers = this.state.careers;
    careers.splice(item, 1);
    this.setState({ careers });
  };

  handleChange = event => {
    // console.log(event.currentTarget.name);
    const value = { [event.target.name]: event.currentTarget.value };
    // console.log(value);
    this.setState(value);
  };
  submit = () => {
    const { type } = this.state;
    const careers = { careers: this.state.careers };
    // console.log(careers);
    this.props.submit(type, careers);
  };
  render() {
    const { submit, removeCareer, handleChange, addCareer } = this;
    const { career, careers } = this.state;
    return (
      <div className="columns is-multiline">
        <div className="column is-full">
          <b className="subtitle is-4">Careers of Interest</b>
          <p>
            <b>
              Please list any future careers you may be interested in pursuing
            </b>
          </p>
        </div>

        <div className="column is-10">
          <div className="field">
            <div className="control">
              <label htmlFor="career">Career Name</label>
              <input
                className="input is-medium"
                type="text"
                name="career"
                value={career}
                onChange={handleChange}
              />
            </div>
          </div>
        </div>

        <div className="column is-2 flex flex-end flex-column">
          <button
            className="button is-medium is-info"
            onClick={addCareer}
            disabled={!career}
          >
            Add
          </button>
        </div>

        {careers && careers.length > 0 ? (
          <div className="column is-full">
            <div className="box">
              <table className="table is-fullwidth">
                <thead>
                  <tr>
                    <th>Career</th>
                    <th className="has-text-right">Delete</th>
                  </tr>
                </thead>
                <tbody>
                  {careers.map((career, index) => (
                    <tr key={index}>
                      <td>{career.name}</td>
                      <td className="has-text-right">
                        <button
                          className="button is-medium is-danger"
                          onClick={event => removeCareer(event, index)}
                        >
                          <i className="fas fa-times" />
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        ) : null}

        <div className="column is-full">
          <button
            type="submit"
            className="button is-large is-primary is-pulled-right"
            name="next"
            onClick={event =>
              this.setState({ type: event.currentTarget.name }, () => submit())
            }
          >
            Next
          </button>
          <button
            type="submit"
            className="button is-large is-pulled-right"
            name="back"
            onClick={event =>
              this.setState({ type: event.currentTarget.name }, () => submit())
            }
          >
            Back
          </button>
        </div>
      </div>
    );
  }
}

export default CareerInterest;
