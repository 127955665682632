import React, { Component } from 'react';
import { Formik, Field } from 'formik';
import { withRouter } from 'react-router-dom';

class Login extends Component {
	componentDidMount() {
		const { isRegistrating, isResettingPassword, isLoggingIn } = this.props;
		if (isRegistrating) {
			this.initialValues = {
				email: '',
				password: '',
				firstName: '',
				lastName: '',
				isAdmin: false
			};
		} else if (isLoggingIn) {
			this.initialValues = {
				email: '',
				password: ''
			};
		} else if (isResettingPassword) {
			this.initialValues = {
				email: ''
			};
		}
	}
	render() {
		const {
			isRegistrating,
			isModalOpen,
			toggleModalAction,
			registerAction,
			isResettingPassword,
			forgotPassword,
			loginAction,
			isLoggingIn,
			history,
			userExists
		} = this.props;

		let initialValues = {
			email: '',
			password: '',
			firstName: '',
			lastName: '',
			isAdmin: false
		};

		return (
			<div className={isModalOpen ? 'modal is-active' : 'modal'}>
				<div className="modal-background" onClick={toggleModalAction} />
				<div className="modal-card">
					<header className="modal-card-head">
						<p className="modal-card-title">
							{isRegistrating ? 'Register' : null}
							{isResettingPassword ? 'Password Reset' : null}
							{isLoggingIn ? 'Login' : null}
						</p>
						<button className="delete" aria-label="close" onClick={toggleModalAction} />
					</header>
					<section className="modal-card-body">
						<Formik
							initialValues={initialValues}
							validate={values => {
								let errors = {};
								if (!values.email) {
									errors.email = 'Required';
								} else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
									errors.email = 'Invalid email address';
								}
								return errors;
							}}
							onSubmit={(values, { setSubmitting }) => {
								setTimeout(() => {
									if (isRegistrating) {
										registerAction(values, history);
									} else if (isResettingPassword) {
										forgotPassword(values.email);
									} else if (isLoggingIn) {
										loginAction(values, history);
									}
									setSubmitting(false);
								}, 400);
							}}
						>
							{({
								values,
								errors,
								touched,
								handleChange,
								handleBlur,
								handleSubmit,
								isSubmitting
								/* and other goodies */
							}) => (
								<form onSubmit={handleSubmit}>
									{isRegistrating ? (
										<React.Fragment>
											<div className="columns">
												<div className="column is-half">
													<div className="field">
														<div className="control">
															<Field
																type="name"
																name="firstName"
																onChange={handleChange}
																onBlur={handleBlur}
																value={values.firstName}
																placeholder="First Name"
																className="input is-medium"
															/>
														</div>
													</div>
													{errors.lastName && touched.lastName && errors.lastName}
												</div>
												<div className="column is-half">
													<div className="field">
														<div className="control">
															<Field
																type="name"
																name="lastName"
																onChange={handleChange}
																onBlur={handleBlur}
																value={values.lastName}
																placeholder="Last Name"
																className="input is-medium"
															/>
														</div>
													</div>
													{errors.lastName && touched.lastName && errors.lastName}
												</div>
											</div>
										</React.Fragment>
									) : null}

									<div className="columns">
										<div className="column is-full">
											<div className="field">
												<div className="control">
													<Field
														type="email"
														name="email"
														onChange={handleChange}
														onBlur={handleBlur}
														value={values.email}
														placeholder="Email"
														className="input is-medium"
													/>
												</div>
											</div>
											{errors.email && touched.email && errors.email}
										</div>
									</div>
									{isResettingPassword ? null : (
										<div className="columns">
											<div className="column is-full">
												<div className="field">
													<div className="control">
														<Field
															type="password"
															name="password"
															onChange={handleChange}
															onBlur={handleBlur}
															value={values.password}
															placeholder="Password"
															className="input is-medium"
														/>
													</div>
												</div>
												{errors.password && touched.password && errors.password}
											</div>
										</div>
									)}
									<div className="flex flex-center flex-end">
										{isLoggingIn || (userExists && !isResettingPassword) ? (
											<span
												className="toggle-modal-btn"
												onClick={toggleModalAction}
												title="reset"
											>
												Forgot Password?
											</span>
										) : null}

										{isResettingPassword ? (
											<span
												title="back-reset"
												className="toggle-modal-btn"
												onClick={toggleModalAction}
											>
												Back
											</span>
										) : null}

										<button type="submit" className="button is-primary">
											Submit
										</button>
									</div>
								</form>
							)}
						</Formik>
					</section>
					<footer className="modal-card-foot" />
				</div>
			</div>
		);
	}
}

export default withRouter(Login);
