import React, { Component } from 'react';

class InfoBox extends Component {
	render() {
		const { type, isVisible, dismiss, isFixed, children } = this.props;
		return (
			<div className={`info-box notification ${type} ${isVisible ? 'show' : 'hide'} ${isFixed ? 'fixed' : null}`}>
				<button className="delete" onClick={dismiss} />
				{children}
			</div>
		);
	}
}

export default InfoBox;
