import React, { Component } from 'react';
import { Formik, Field } from 'formik';

class GeneralInformation extends Component {
	initialData = {
		firstName: '',
		lastName: '',
		relationship: '',
		school_org: '',
		address: '',
		city: '',
		state: '',
		zip: '',
		telephone: '',
		fax: '',
		email: ''
	}
	render() {
		const { submit } = this.props;
		const { initialData } = this;
		return (
			<Formik
			initialValues={initialData}
				onSubmit={(values, { setSubmitting }) => {
					setTimeout(() => {
						submit(values);
						setSubmitting(false);
					}, 500);
				}}
			>
				{props => {
					const { isSubmitting, handleSubmit, handleChange } = props;
					return (
						<form onSubmit={handleSubmit} className="columns is-multiline">
							<div className="column is-full">
								<div className="title is-3">General Information</div>
							</div>
							<div className="column is-half">
								<div className="field">
									<div className="control">
										<label htmlFor="firstName">First Name:*</label>
										<Field className="input is-medium" type="text" name="firstName" required />
									</div>
								</div>
							</div>

							<div className="column is-half">
								<div className="field">
									<div className="control">
										<label htmlFor="lastName">Last Name:*</label>
										<Field className="input is-medium" type="text" name="lastName" required />
									</div>
								</div>
							</div>

							<div className="column is-half">
								<div className="field">
									<div className="control">
										<label htmlFor="relationship">Relationship to Applicant:*</label>
										<Field className="input is-medium" type="text" name="relationship" required />
									</div>
								</div>
							</div>

							<div className="column is-half">
								<div className="field">
									<div className="control">
										<label htmlFor="school_org">School/Organization:*</label>
										<Field className="input is-medium" type="text" name="school_org" required />
									</div>
								</div>
							</div>

							<div className="column is-half">
								<div className="field">
									<div className="control">
										<label htmlFor="address">Address:*</label>
										<Field className="input is-medium" type="text" name="address" required />
									</div>
								</div>
							</div>

							<div className="column is-half">
								<div className="field">
									<div className="control">
										<label htmlFor="city">City:*</label>
										<Field className="input is-medium" type="text" name="city" required />
									</div>
								</div>
							</div>

							<div className="column is-half">
								<div className="field">
									<div className="control">
										<label htmlFor="state">State:*</label>
										<Field
											className="input is-medium"
											component="select"
											name="state"
											required
											onChange={handleChange}
										>
											<option value="">Select</option>
											<option value="AL">Alabama</option>
											<option value="AK">Alaska</option>
											<option value="AZ">Arizona</option>
											<option value="AR">Arkansas</option>
											<option value="CA">California</option>
											<option value="CO">Colorado</option>
											<option value="CT">Connecticut</option>
											<option value="DE">Delaware</option>
											<option value="DC">District Of Columbia</option>
											<option value="FL">Florida</option>
											<option value="GA">Georgia</option>
											<option value="HI">Hawaii</option>
											<option value="ID">Idaho</option>
											<option value="IL">Illinois</option>
											<option value="IN">Indiana</option>
											<option value="IA">Iowa</option>
											<option value="KS">Kansas</option>
											<option value="KY">Kentucky</option>
											<option value="LA">Louisiana</option>
											<option value="ME">Maine</option>
											<option value="MD">Maryland</option>
											<option value="MA">Massachusetts</option>
											<option value="MI">Michigan</option>
											<option value="MN">Minnesota</option>
											<option value="MS">Mississippi</option>
											<option value="MO">Missouri</option>
											<option value="MT">Montana</option>
											<option value="NE">Nebraska</option>
											<option value="NV">Nevada</option>
											<option value="NH">New Hampshire</option>
											<option value="NJ">New Jersey</option>
											<option value="NM">New Mexico</option>
											<option value="NY">New York</option>
											<option value="NC">North Carolina</option>
											<option value="ND">North Dakota</option>
											<option value="OH">Ohio</option>
											<option value="OK">Oklahoma</option>
											<option value="OR">Oregon</option>
											<option value="PA">Pennsylvania</option>
											<option value="RI">Rhode Island</option>
											<option value="SC">South Carolina</option>
											<option value="SD">South Dakota</option>
											<option value="TN">Tennessee</option>
											<option value="TX">Texas</option>
											<option value="UT">Utah</option>
											<option value="VT">Vermont</option>
											<option value="VA">Virginia</option>
											<option value="WA">Washington</option>
											<option value="WV">West Virginia</option>
											<option value="WI">Wisconsin</option>
											<option value="WY">Wyoming</option>
										</Field>
									</div>
								</div>
							</div>

							<div className="column is-half">
								<div className="field">
									<div className="control">
										<label htmlFor="zip">Zip:*</label>
										<Field className="input is-medium" type="text" name="zip" required />
									</div>
								</div>
							</div>

							<div className="column is-half">
								<div className="field">
									<div className="control">
										<label htmlFor="telephone">Telephone:*</label>
										<Field className="input is-medium" type="text" name="telephone" required />
									</div>
								</div>
							</div>

							<div className="column is-half">
								<div className="field">
									<div className="control">
										<label htmlFor="fax">Fax:</label>
										<Field className="input is-medium" type="text" name="fax" />
									</div>
								</div>
							</div>

							<div className="column is-half">
								<div className="field">
									<div className="control">
										<label htmlFor="email">Email:*</label>
										<Field className="input is-medium" type="text" name="email" required />
									</div>
								</div>
							</div>

							<div className="column is-full">
								<button
									type="submit"
									disabled={isSubmitting}
									className="button is-large is-pulled-right is-primary"
								>
									Next
								</button>
							</div>
						</form>
					);
				}}
			</Formik>
		);
	}
}

export default GeneralInformation;
