import React, { Component } from "react";

class Contract extends Component {
  constructor(props) {
    super(props);
    const { firstName, lastName } = props.formData;
    this.state = {
      signature: "",
      type: null,
      validationSignature: firstName + ' ' + lastName,
    };
  }
  handleChange = event => {
    // console.log(event.currentTarget.name);
    const value = { [event.target.name]: event.currentTarget.value };
    // console.log(value);
    this.setState(value);
  };
  validateSignature = (event) => {
    let error;
    const { signature, validationSignature } = this.state;
    if (signature !== validationSignature) {
      error = `Signature does not match: ${validationSignature}`;
      alert(error);
    } else {
      this.setState({ type: event.currentTarget.name }, () =>
        this.submit()
      )
    }
  }
  submit = () => {
    // console.log(this.state);
    const { type, signature } = this.state;
    const agree = { signature };
    this.props.submit(type, agree);
  };
  render() {
    const { handleChange, validateSignature } = this;
    const { signature } = this.state;
    return (
      <div className="box is-paddingless">
        <div className="message is-primary">
          <div className="message-header">
            <p>Contract Agreement</p>
          </div>
          <div className="message-body">
            <div className="content">
              <p>
                By clicking the submit button I agree to the following
                guidelines and expectations established by the Turn 2 Foundation
                and the Jeter’s Leaders Program:
              </p>

              <ul>
                <li>To be a role model for other students</li>
                <li>To stay drug and alcohol free</li>
                <li>Have a minimum GPA of 3.0 (B)</li>
                <li>
                  To display appropriate behavior and demonstrate respect for
                  myself and others
                </li>
                <li>
                  To fully participate in the Jeter’s Leaders meetings,
                  activities and trips; I understand that there are mandatory
                  meetings, activities and trips that require my full
                  participation and if I fail to participate, it will lead to my
                  immediate dismissal
                </li>
              </ul>
              <div className="columns is-multiline">
                <div className="column is-full">
                  <label htmlFor="signature">Printed Name</label>
                  <input
                    type="text"
                    name="signature"
                    id="signature"
                    className="input"
                    value={signature}
                    onChange={handleChange}
                  />
                </div>
                <div className="column is-full">
                  <button
                    className="button is-large is-pulled-right is-primary"
                    onClick={event =>
                      validateSignature(event)
                    }
                    name="next"
                  >
                    I Agree
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div >
    );
  }
}

export default Contract;
