import React, { Component } from "react";
import { Formik, Field } from "formik";

class ShortAnswers extends Component {
  initialData = {
    strengths: "",
    activities: "",
    leadership: "",
    benefits: "",
    contribute: "",
    agree: false,
  };

  render() {
    const { submit, back } = this.props;
    const { initialData } = this;
    return (
      <Formik
        initialValues={initialData}
        onSubmit={(values, { setSubmitting }) => {
          if (values.agree !== true) {
            alert(
              `Please confirm the information provided is accurate by clicking on the checkbox`
            );
            return false;
          }
          if (values.agree === true) {
            setTimeout(() => {
              submit(values);
            }, 500);
          }
          setSubmitting(false);
        }}
      >
        {props => {
          const { isSubmitting, handleSubmit, handleChange } = props;
          return (
            <form onSubmit={handleSubmit} className="columns is-multiline">
              <div className="column is-full">
                <div className="title is-3">Short Answers</div>
                <b>
                  PLEASE ANSWER THE FOLLOWING QUESTIONS IN THE SPACE PROVIDED.
                </b>
              </div>

              <div className="column is-full">
                <label htmlFor="strengths">
                  What are the applicant’s outstanding strengths?
                </label>
                <textarea
                  onChange={handleChange}
                  required
                  className="textarea is-full"
                  name="strengths"
                  id="strengths"
                />
              </div>

              <div className="column is-full">
                <label htmlFor="activities">
                  List known activities the applicant has been involved in that
                  will help him/her contribute to the Program.
                </label>
                <textarea
                  onChange={handleChange}
                  required
                  className="textarea is-full"
                  name="activities"
                  id="activities"
                />
              </div>

              <div className="column is-full">
                <label htmlFor="leadership">
                  What leadership qualities does the applicant have that would
                  make him/her an asset to the Program?
                </label>
                <textarea
                  onChange={handleChange}
                  required
                  className="textarea is-full"
                  name="leadership"
                  id="leadership"
                />
              </div>

              <div className="column is-full">
                <label htmlFor="benefits">
                  In what ways will the applicant benefit from the Jeter’s
                  Leaders Program?
                </label>
                <textarea
                  onChange={handleChange}
                  required
                  className="textarea is-full"
                  name="benefits"
                  id="benefits"
                />
              </div>

              <div className="column is-full">
                <label htmlFor="contribute">
                  In what ways will the applicant be able to contribute to the
                  Jeter’s Leaders Program?
                </label>
                <textarea
                  onChange={handleChange}
                  required
                  className="textarea is-full"
                  name="contribute"
                  id="contribute"
                />
              </div>

              <div className="column">                
                <label>
                  <Field type="checkbox" name="agree" style={{marginRight: '1rem'}} />
                  By checking this box, I confirm that all the information provided in this form is accurate and complete to the best of my knowledge.
                </label>
              </div>

              <div className="column is-full">
                <button
                  type="submit"
                  disabled={isSubmitting}
                  className="button is-large is-pulled-right is-primary"
                >
                  Next
                </button>
                <button
                  onClick={back}
                  className="button is-large is-pulled-right"
                >
                  Back
                </button>
              </div>
            </form>
          );
        }}
      </Formik>
    );
  }
}

export default ShortAnswers;
