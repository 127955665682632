import React, { Component } from "react";
import _ from "lodash";

class FileUpload extends Component {
  saveFile = file => {
    // console.log("----- File -----");
    // console.log(file);
    const saveFile = document.getElementById(file).files;
    // console.log("----- Save File -----");
    // console.log(saveFile);
    if (saveFile.length > 0) {
      this.props.saveFile(saveFile[0], file);
    }
  };
  render() {
    const label = _.snakeCase(this.props.label);
    const { saveFile } = this;
    return (
      <div>
        <label htmlFor={label} className="field-label">
          {_.startCase(label)}
        </label>
        <div className="field has-addons">
          <div className="control">
            <input
              className="input"
              type="file"
              name={_.snakeCase(label)}
              id={label}
              accept="image/*, .doc, .docx, .pdf"
            />
          </div>
          <div className="control">
            <button className="button is-info" onClick={() => saveFile(label)}>
              Upload
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default FileUpload;
