import React, { Component } from "react";
import { Formik } from "formik";

class LeadershipQualities extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isVisibleAcademic: false,
      isVisibleImagination: false,
      isVisibleMotivation: false,
      isVisibleWork: false,
      isVisibleLead: false,
      isVisibleSucceed: false,
    };
  }
  setDetails = e => {
    const { name, value } = e.currentTarget;
    let newName =
      name
        .split("_")[0]
        .charAt(0)
        .toUpperCase() + name.split("_")[0].slice(1);
    let state = `isVisible${newName}`;
    if (value === "Average" || value === "Below Average") {
      this.setState(nextState => {
        nextState[state] = true;
        return {
          nextState,
        };
      });
    } else {
      this.setState(nextState => {
        nextState[state] = false;
        return {
          nextState,
        };
      });
    }
  };
  initialData = {
    academic_ability: '',
    academic_ability_details: '',
    imagination_creativity: '',
    imagination_creativity_details: '',
    motivation_initiative: '',
    motivation_initiative_details: '',
    work_with_others: '',
    work_with_others_details: '',
    lead_group: '',
    lead_group_details: '',
    succeed_leadership: '',
    succeed: ''

  }
  render() {
    const { submit, back } = this.props;
    const {
      isVisibleAcademic,
      isVisibleImagination,
      isVisibleMotivation,
      isVisibleWork,
      isVisibleLead,
      isVisibleSucceed,
    } = this.state;
    const { setDetails, initialData } = this;
    return (
      <Formik
        initialValues={initialData}
        onSubmit={(values, { setSubmitting }) => {
          setTimeout(() => {
            submit(values);
            setSubmitting(false);
          }, 500);
        }}
      >
        {props => {
          const { isSubmitting, handleSubmit, handleChange } = props;
          return (
            <form onSubmit={handleSubmit} className="columns is-multiline">
              <div className="column is-full">
                <div className="title is-3">Leadership Qualities</div>
                <b>
                  PLEASE RATE THE APPLICANT BY CHECKING THE APPROPRIATE BOX.
                  PLACE ONLY ONE CHECK PER CATEGORY.
                </b>
              </div>

              <div className="column is-full">
                <div className="flex flex-between">
                  <b className="subtitle is-4">General Academic Ability</b>
                  <div className="field">
                    <input
                      className="is-checkradio is-medium"
                      type="radio"
                      name="academic_ability"
                      id="academic_ability_above_average"
                      value="Above Average"
                      onChange={handleChange}
                      onClick={setDetails}
                      required
                    />
                    <label htmlFor="academic_ability_above_average">
                      Above Average
                    </label>
                    <input
                      className="is-checkradio is-medium"
                      type="radio"
                      id="academic_ability_average"
                      name="academic_ability"
                      value="Average"
                      onChange={handleChange}
                      onClick={setDetails}
                      required
                    />
                    <label htmlFor="academic_ability_average">Average</label>
                    <input
                      className="is-checkradio is-medium"
                      type="radio"
                      id="academic_ability_below_average"
                      name="academic_ability"
                      value="Below Average"
                      onChange={handleChange}
                      onClick={setDetails}
                      required
                    />
                    <label htmlFor="academic_ability_below_average">
                      Below Average
                    </label>
                  </div>
                </div>
              </div>

              {isVisibleAcademic && (
                <div className="column is-full">
                  <div className="field">
                    <p>Explain why.</p>
                    <textarea
                      className="textarea"
                      name="academic_ability_details"
                      id="academic_ability_details"
                      required={isVisibleAcademic}
                      onChange={handleChange}
                    />
                  </div>
                </div>
              )}

              <div className="column is-full">
                <div className="flex flex-between">
                  <b className="subtitle is-4">Imagination and Creativity</b>
                  <div className="field">
                    <input
                      className="is-checkradio is-medium"
                      type="radio"
                      name="imagination_creativity"
                      id="imagination_creativity_above_average"
                      value="Above Average"
                      onChange={handleChange}
                      onClick={setDetails}
                      required
                    />
                    <label htmlFor="imagination_creativity_above_average">
                      Above Average
                    </label>
                    <input
                      className="is-checkradio is-medium"
                      type="radio"
                      id="imagination_creativity_average"
                      name="imagination_creativity"
                      value="Average"
                      onChange={handleChange}
                      onClick={setDetails}
                      required
                    />
                    <label htmlFor="imagination_creativity_average">
                      Average
                    </label>
                    <input
                      className="is-checkradio is-medium"
                      type="radio"
                      id="imagination_creativity_below_average"
                      name="imagination_creativity"
                      value="Below Average"
                      onChange={handleChange}
                      onClick={setDetails}
                      required
                    />
                    <label htmlFor="imagination_creativity_below_average">
                      Below Average
                    </label>
                  </div>
                </div>
              </div>

              {isVisibleImagination && (
                <div className="column is-full">
                  <div className="field">
                    <p>Explain why.</p>
                    <textarea
                      className="textarea"
                      name="imagination_creativity_details"
                      id="imagination_creativity_details"
                      required={isVisibleImagination}
                      onChange={handleChange}
                    />
                  </div>
                </div>
              )}

              <div className="column is-full">
                <div className="flex flex-between">
                  <b className="subtitle is-4">Motivation and Initiative</b>
                  <div className="field">
                    <input
                      className="is-checkradio is-medium"
                      type="radio"
                      name="motivation_initiative"
                      id="motivation_initiative_above_average"
                      value="Above Average"
                      onChange={handleChange}
                      onClick={setDetails}
                      required
                    />
                    <label htmlFor="motivation_initiative_above_average">
                      Above Average
                    </label>
                    <input
                      className="is-checkradio is-medium"
                      type="radio"
                      id="motivation_initiative_average"
                      name="motivation_initiative"
                      value="Average"
                      onChange={handleChange}
                      onClick={setDetails}
                      required
                    />
                    <label htmlFor="motivation_initiative_average">
                      Average
                    </label>
                    <input
                      className="is-checkradio is-medium"
                      type="radio"
                      id="motivation_initiative_below_average"
                      name="motivation_initiative"
                      value="Below Average"
                      onChange={handleChange}
                      onClick={setDetails}
                      required
                    />
                    <label htmlFor="motivation_initiative_below_average">
                      Below Average
                    </label>
                  </div>
                </div>
              </div>

              {isVisibleMotivation && (
                <div className="column is-full">
                  <div className="field">
                    <p>Explain why.</p>
                    <textarea
                      className="textarea"
                      name="motivation_initiative_details"
                      id="motivation_initiative_details"
                      required={isVisibleMotivation}
                      onChange={handleChange}
                    />
                  </div>
                </div>
              )}

              <div className="column is-full">
                <div className="flex flex-between">
                  <b className="subtitle is-4">Ability to Work with Others</b>
                  <div className="field">
                    <input
                      className="is-checkradio is-medium"
                      type="radio"
                      name="work_with_others"
                      id="work_with_others_above_average"
                      value="Above Average"
                      onChange={handleChange}
                      onClick={setDetails}
                      required
                    />
                    <label htmlFor="work_with_others_above_average">
                      Above Average
                    </label>
                    <input
                      className="is-checkradio is-medium"
                      type="radio"
                      id="work_with_others_average"
                      name="work_with_others"
                      value="Average"
                      onChange={handleChange}
                      onClick={setDetails}
                      required
                    />
                    <label htmlFor="work_with_others_average">Average</label>
                    <input
                      className="is-checkradio is-medium"
                      type="radio"
                      id="work_with_others_below_average"
                      name="work_with_others"
                      value="Below Average"
                      onChange={handleChange}
                      onClick={setDetails}
                      required
                    />
                    <label htmlFor="work_with_others_below_average">
                      Below Average
                    </label>
                  </div>
                </div>
              </div>

              {isVisibleWork && (
                <div className="column is-full">
                  <div className="field">
                    <p>Explain why.</p>
                    <textarea
                      className="textarea"
                      name="work_with_others_details"
                      id="work_with_others_details"
                      required={isVisibleWork}
                      onChange={handleChange}
                    />
                  </div>
                </div>
              )}

              <div className="column is-full">
                <div className="flex flex-between">
                  <b className="subtitle is-4">Capability to Lead a Group</b>
                  <div className="field">
                    <input
                      className="is-checkradio is-medium"
                      type="radio"
                      name="lead_group"
                      id="lead_group_above_average"
                      value="Above Average"
                      onChange={handleChange}
                      onClick={setDetails}
                      required
                    />
                    <label htmlFor="lead_group_above_average">
                      Above Average
                    </label>
                    <input
                      className="is-checkradio is-medium"
                      type="radio"
                      id="lead_group_average"
                      name="lead_group"
                      value="Average"
                      onChange={handleChange}
                      onClick={setDetails}
                      required
                    />
                    <label htmlFor="lead_group_average">Average</label>
                    <input
                      className="is-checkradio is-medium"
                      type="radio"
                      id="lead_group_below_average"
                      name="lead_group"
                      value="Below Average"
                      onChange={handleChange}
                      onClick={setDetails}
                      required
                    />
                    <label htmlFor="lead_group_below_average">
                      Below Average
                    </label>
                  </div>
                </div>
              </div>

              {isVisibleLead && (
                <div className="column is-full">
                  <div className="field">
                    <p>Explain why.</p>
                    <textarea
                      className="textarea"
                      name="lead_group_details"
                      id="lead_group_details"
                      required={isVisibleLead}
                      onChange={handleChange}
                    />
                  </div>
                </div>
              )}

              <div className="column is-full">
                <div className="flex flex-between">
                  <b className="subtitle is-4">
                    Potential to Succeed in a Leadership Program
                  </b>
                  <div className="field">
                    <input
                      className="is-checkradio is-medium"
                      type="radio"
                      name="succeed_leadership"
                      id="succeed_leadership_above_average"
                      value="Above Average"
                      onChange={handleChange}
                      onClick={setDetails}
                      required
                    />
                    <label htmlFor="succeed_leadership_above_average">
                      Above Average
                    </label>
                    <input
                      className="is-checkradio is-medium"
                      type="radio"
                      id="succeed_leadership_average"
                      name="succeed_leadership"
                      value="Average"
                      onChange={handleChange}
                      onClick={setDetails}
                      required
                    />
                    <label htmlFor="succeed_leadership_average">Average</label>
                    <input
                      className="is-checkradio is-medium"
                      type="radio"
                      id="succeed_leadership_below_average"
                      name="succeed_leadership"
                      value="Below Average"
                      onChange={handleChange}
                      onClick={setDetails}
                      required
                    />
                    <label htmlFor="succeed_leadership_below_average">
                      Below Average
                    </label>
                  </div>
                </div>
              </div>

              {isVisibleSucceed && (
                <div className="column is-full">
                  <div className="field">
                    <p>Explain why.</p>
                    <textarea
                      className="textarea"
                      name="succeed_leadership_details"
                      id="succeed_leadership_details"
                      required={isVisibleSucceed}
                      onChange={handleChange}
                    />
                  </div>
                </div>
              )}

              <div className="column is-full">
                <button
                  type="submit"
                  disabled={isSubmitting}
                  className="button is-large is-pulled-right is-primary"
                >
                  Next
                </button>
                <button
                  onClick={back}
                  className="button is-large is-pulled-right"
                >
                  Back
                </button>
              </div>
            </form>
          );
        }}
      </Formik>
    );
  }
}

export default LeadershipQualities;
