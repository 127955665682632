import React, { Component } from "react";
import { Formik, Field } from "formik";

class GuardianDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      type: null,
    };
  }
  render() {
    const { formData, submit } = this.props;
    const { type } = this.state;
    return (
      <Formik
        initialValues={formData}
        onSubmit={(values, { setSubmitting }) => {
          setTimeout(() => {
            submit(type, values);
            setSubmitting(false);
          }, 400);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
        }) => (
          <form onSubmit={handleSubmit} className="columns is-multiline">
            <div className="column is-full">
              <b className="subtitle is-4">Guardian Details</b>
            </div>

            <div className="column is-half">
              <div className="field">
                <div className="control">
                  <label htmlFor="parentName">Guardian's Name*</label>
                  <Field
                    className="input is-medium"
                    type="text"
                    name="parentName"
                    required
                  />
                </div>
              </div>
            </div>
            <div className="column is-half">
              <div className="field">
                <div className="control">
                  <label htmlFor="parentPhone">Daytime Phone Number*</label>
                  <Field
                    className="input is-medium"
                    type="tel"
                    name="parentPhone"
                    required
                  />
                </div>
              </div>
            </div>
            <div className="column is-half">
              <div className="field">
                <div className="control">
                  <label htmlFor="parentEmail">Email*</label>
                  <Field
                    className="input is-medium"
                    type="email"
                    name="parentEmail"
                    required
                  />
                </div>
              </div>
            </div>

            <div className="column is-full">
              <button
                type="submit"
                className="button is-large is-primary is-pulled-right"
                name="next"
                onClick={event =>
                  this.setState({ type: event.currentTarget.name })
                }
              >
                Next
              </button>
              <button
                type="submit"
                className="button is-large is-pulled-right"
                name="back"
                onClick={event =>
                  this.setState({ type: event.currentTarget.name })
                }
              >
                Back
              </button>
            </div>
          </form>
        )}
      </Formik>
    );
  }
}

export default GuardianDetails;
