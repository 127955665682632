import React, { Component } from 'react';
import { CSVLink } from 'react-csv';
import _ from 'lodash';
import {fixFileLinks} from '../utils';
class CSVFile extends Component {
	render() {
		const { data } = this.props;
		let users = [];
		let headers = [];
		
		if (data) {
			data.map(user => {
				const row = Object.assign({}, user.attributes);
				if (row && row.activities) {
					row.activities.map((activity, index) => {
						row[`Activity ${index + 1} Start Date`] = activity.startDate;
						row[`Activity ${index + 1} End Date`] = activity.endDate;
						row[`Activity ${index + 1}`] = activity.name;
						return row;
					});
				}
				if (row && row.hobbies) {
					row.hobbies.map((hobby, index) => {
						return (row[`Hobby ${index + 1}`] = hobby.name);
					});
				}
				if (row && row.careers) {
					row.careers.map((career, index) => {
						return (row[`Career ${index + 1}`] = career.name);
					});
				}
				if (row && row.colleges) {
					row.colleges.map((college, index) => {
						return (row[`College ${index + 1}`] = college.name);
					});
				}
				if (row && row.report_card) {
					row.report_card = fixFileLinks(row.report_card);
				}
				if (row && row.essay) {
					row.essay = fixFileLinks(row.essay);
				}
				delete row.ACL;
				delete row.createdAt;
				delete row.updatedAt;
				delete row.activities;
				delete row.hobbies;
				delete row.careers;
				delete row.colleges;
				delete row.isAdmin;
				delete row.step;
				delete row.completed;
				delete row.username;
				Object.entries(row).map(([key, value]) => {
					return headers.push({ [key]: key, key, label: _.startCase(key) });
				});
				return users.push(row);
			});

			headers = _.uniqWith(_.sortBy(headers, ['label']), _.isEqual);

			// headers.splice()
		}
		return (
			<CSVLink
				data={users}
				headers={_.sortBy(headers, [
					'firstName',
					'lastName',
					'signature',
					'mobile',
					'email',
					'phone',
					'birthdate',
					'age',
					'address',
					'apt',
					'city',
					'state',
					'zipcode',
					'parentName',
					'parentEmail',
					'parentPhone',
					'school',
					'current_school',
					'high_school',
					'high_school_confirmed',
					'grade',
					'principal',
					'counselor',
					'report_card',
					'essay'
				])}
				className="button is-primary is-fullwidth-mobile"
				filename={'applicants.csv'}
			>
				Download Spreadsheet
			</CSVLink>
		);
	}
}

export default CSVFile;
