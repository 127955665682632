import React, { Component } from "react";

class CollegeInterest extends Component {
  constructor(props) {
    super(props);
    this.state = {
      colleges: [],
      type: null,
      college: "",
    };
  }
  componentDidMount() {
    const { colleges } = this.props.formData;
    if (colleges && colleges.length > 0) {
      this.setState({ colleges });
    }
  }
  addCollege = e => {
    e.preventDefault();
    let { college } = this.state;
    this.setState(prevState => {
      let colleges = prevState.colleges;
      const entry = { name: college };
      colleges.push(entry);
      college = "";
      return {
        colleges,
        college,
      };
    });
  };
  removeCollege = (e, item) => {
    e.preventDefault();
    let colleges = this.state.colleges;
    colleges.splice(item, 1);
    this.setState({ colleges });
  };

  handleChange = event => {
    // console.log(event.currentTarget.name);
    const value = { [event.target.name]: event.currentTarget.value };
    // console.log(value);
    this.setState(value);
  };
  submit = () => {
    const { type } = this.state;
    const colleges = { colleges: this.state.colleges };
    // console.log(colleges);
    this.props.submit(type, colleges);
  };
  render() {
    const { college, colleges } = this.state;
    const { addCollege, handleChange, removeCollege, submit } = this;
    return (
      <div className="columns is-multiline">
        <div className="column is-full">
          <b className="subtitle is-4">Colleges of Interest</b>
          <p>
            <b>
              Please list any colleges, universities, institutes, trade schools,
              etc. that you may be interested in attending in the future
            </b>
          </p>
        </div>

        <div className="column is-10">
          <div className="field">
            <div className="control">
              <label htmlFor="activity">College Name</label>
              <input
                className="input is-medium"
                type="text"
                name="college"
                value={college}
                onChange={handleChange}
              />
            </div>
          </div>
        </div>

        <div className="column is-2 flex flex-end flex-column">
          <button
            className="button is-medium is-info"
            onClick={addCollege}
            disabled={!college}
          >
            Add
          </button>
        </div>

        {colleges && colleges.length > 0 ? (
          <div className="column is-full">
            <div className="box">
              <table className="table is-fullwidth">
                <thead>
                  <tr>
                    <th>College</th>
                    <th className="has-text-right">Delete</th>
                  </tr>
                </thead>
                <tbody>
                  {colleges.map((college, index) => (
                    <tr key={index}>
                      <td>{college.name}</td>
                      <td className="has-text-right">
                        <button
                          className="button is-medium is-danger"
                          onClick={event => removeCollege(event, index)}
                        >
                          <i className="fas fa-times" />
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        ) : null}

        <div className="column is-full">
          <button
            type="submit"
            className="button is-large is-primary is-pulled-right"
            name="next"
            onClick={event =>
              this.setState({ type: event.currentTarget.name }, () => submit())
            }
          >
            Next
          </button>
          <button
            type="submit"
            className="button is-large is-pulled-right"
            name="back"
            onClick={event =>
              this.setState({ type: event.currentTarget.name }, () => submit())
            }
          >
            Back
          </button>
        </div>
      </div>
    );
  }
}

export default CollegeInterest;
