import React, { Component } from 'react';
import { getYear } from 'date-fns';

class Footer extends Component {
	render() {
		const currentYear = getYear(new Date());
		return (
			<section className="section">
				<div className="columns">
					<div className="column">
						<div className="has-text-centered">
							<p>© {currentYear} TURN 2 FOUNDATION, INC. | All rights reserved.</p>
						</div>
					</div>
				</div>
			</section>
		);
	}
}

export default Footer;
